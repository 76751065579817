@use '../../../scss/abstracts/' as *;

.checkbox-field {
  min-width: 8px;
  display: flex;
  align-items: center;
  gap: 12px;

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: white;

    &.disabled {
      background-color: #f5f5f5;
    }

    input[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;

      &:hover:disabled {
        cursor: not-allowed;
      }
    }
  }

  .light {
    background: #009ef7;
  }

  .primary {
    background: #1c3d5d;
  }

  &__label {
    font-size: $font-small;
    font-weight: 400;
    color: black;
  }
}
