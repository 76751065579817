@use '../../../../scss/abstracts/' as *;
.detail-order-oneWay {
  position: relative;

  &__publish-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__back-btn {
    display: flex;
    width: fit-content;
    margin-bottom: 21px;

    &:hover {
      cursor: pointer;
    }

    p {
      margin-left: 12px;
      font-size: $font-medium;
    }
  }

  &__selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 28px;
    align-items: flex-end;
    margin-bottom: 18px;
  }

  &__renter-detail,
  &__identity-detail,
  &__rent-detail,
  &__payment-detail {
    &-title {
      margin-bottom: 18px;

      h1 {
        color: $lightBlue;
        font-size: $font-label;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: flex-end;

      &__rent-date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }

      &__phone-number {
        align-items: flex-end;
        gap: 12px;
        display: grid;
        grid-template-columns: 20% auto;

        &-wrapper {
          position: relative;
        }
      }

      &__image-wrapper {
        position: absolute;
        right: 0;
        top: 0;
      }

      &__payment-method {
        &.confirmation {
          display: grid;
          grid-template-columns: auto 30%;
          align-items: flex-end;
          gap: 12px;
        }

        &.not-confirmation {
          display: block;
        }
      }

      &__preview-image {
        position: relative;

        .preview-image-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .preview-image-input {
          .input-text-field {
            padding-right: 100px;
          }
        }

        .validation-identity-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .reupload-icon {
          position: absolute;
          top: 5px;
          right: 8px;
          display: flex;
          align-items: center;
          column-gap: 6px;
          font-size: 12px;
          color: #f1a33a;
        }
      }

      &__input-violation,
      &__total-payment {
        margin-top: 28px;
      }

      &__total-payment {
        width: 50%;
      }

      .text-area-car-package {
        .input-text-area-title {
          margin-bottom: 0;
        }

        .input-text-area-field {
          padding: 12px;
        }
      }
    }
  }

  &__price-detail-title {
    margin-bottom: 18px;

    h1 {
      color: $lightBlue;
      font-size: $font-label;
    }
  }

  &__price-detail {
    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 28px;
      align-items: flex-end;

      &__rent-date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }

      &__phone-number {
        align-items: flex-end;
        gap: 12px;
        display: grid;
        grid-template-columns: 20% auto;

        &-wrapper {
          position: relative;
        }
      }

      &__image-wrapper {
        position: absolute;
        right: 0;
        top: 0;
      }

      &__payment-method {
        &.confirmation {
          display: grid;
          grid-template-columns: auto 30%;
          align-items: flex-end;
          gap: 12px;
        }

        &.not-confirmation {
          display: block;
        }
      }

      &__preview-image {
        position: relative;

        .preview-image-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .preview-image-input {
          .input-text-field {
            padding-right: 100px;
          }
        }
      }

      &__input-violation,
      &__total-payment {
        margin-top: 28px;
      }

      &__total-payment {
        width: 50%;
      }

      .text-area-car-package {
        .input-text-area-title {
          margin-bottom: 0;
        }

        .input-text-area-field {
          padding: 12px;
        }
      }
    }
  }

  &__rent-detail {
    &-body {
      align-items: flex-start;
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__price-detail {
    margin-bottom: 42px;
  }

  &__renter-detail {
    margin-top: 28px;
  }

  &__transaction-buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }

  .input-text-field:disabled {
    background-color: #f7f7f7;
  }

  &__identity-detail-body {
    margin-top: 28px;
  }

  .tab-list {
    border-bottom: 1px solid #e0e0e0;
  }

  .tab-list-item {
    border: 1px solid #e0e0e0;
  }
}
