@use '../../../../../scss/abstracts/' as *;
@forward './RefundDetailForm/refund-detail-form';
@forward './RefundHistory/refund-history';

.detail-refund-dana {
  display: flex;
  flex-direction: column;
  row-gap: 21px;

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    p {
      margin-left: 12px;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    color: #009ef7;
    margin: 28px 0px;
  }

  &__detail {
    .section-btn {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 30px;

      &__button {
        background: white;
        color: #009ef7;
        border: 1px solid #009ef7;
      }

      .active {
        background: #009ef7;
        color: white;
      }
    }
  }
}
