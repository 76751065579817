@use '../../../scss/abstracts/' as *;

.airport-location-modal {
  width: 1105px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 30px;
  margin-top: 16px;

  &__button-field {
    width: 100%;
    grid-column: 2/3;
    grid-row: 3/4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
