@use '../../../../../../scss/abstracts/' as *;

.detail-reward {
  margin-top: 40px;

  h2 {
    font-size: $font-medium;
    margin-bottom: 18px;
  }

  .action-button {
    margin-top: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}
