@use '../../../../scss/abstracts/' as *;

.language {
  width: 100%;

  &__button {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;

    .button-label {
      margin-left: 5px;
    }
  }
}
