@use '../../../scss/abstracts/' as *;

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.2s all;
  min-width: 100px;
  padding: 8px 16px;
  font-weight: 700;
  color: $white;
  font-size: $font-small;
  cursor: pointer;
  border: none;

  &.w-full {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  // button variant
  &.btn--primary {
    background-color: $lightBlue;

    &:hover {
      opacity: 0.75;
    }
  }

  &.btn--outline--dark {
    outline: 1px solid $black;
    background-color: $white;
    color: $black;
    transition: 0.2s all;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  &.btn--danger {
    background-color: #ed0000;
    color: $white;
    transition: 0.2s all;

    &:hover {
      opacity: 0.75;
    }
  }

  &.btn--success {
    background-color: #007a05;
    color: $white;
    transition: 0.2s all;

    &:hover {
      opacity: 0.75;
    }
  }

  // button size
  &.btn--xs {
    padding: 9px 10px;
    font-size: $font-small;
    font-weight: 600;
  }

  &.btn--sm {
    padding: 10.5px 12px;
    font-size: $font-small;
    font-weight: 600;
  }

  &.btn--md {
    padding: 11.5px 8px;
    font-size: $font-medium;
  }

  &.btn--lg {
    padding: 11.5px 100px;
    font-size: $font-medium;
  }
}
