@use '../../../scss/abstracts/' as *;
@forward './ListItemAdditional/';

.select-field-additional-item {
  position: relative;
  width: 100%;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    width: 100%;
    max-width: 100%;
    height: 48px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    cursor: pointer;
    box-sizing: border-box;

    &__input {
      width: 100%;
      padding: 0 14px;
      height: 48px;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;
    }

    &__items-wrapper {
      width: 100%;
      height: 48px;
      padding: 5px 10px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      box-sizing: border-box;

      .item {
        background-color: white;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        margin-right: 8px;
        padding: 3px 6px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        column-gap: 20px;

        &__image {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__info {
          h5 {
            font-size: 10px;
            font-weight: 700;
            margin-bottom: 6px;
          }

          div {
            display: flex;
            align-items: center;
            column-gap: 26px;

            p {
              font-size: 10px;
              font-weight: 400;

              span {
                font-weight: 700;
              }
            }
          }
        }

        &__price {
          display: flex;
          align-items: center;
          column-gap: 10px;

          p {
            font-size: 12px;
            font-weight: 700;
            color: #1c3d5d;
          }

          button {
            width: 19px;
            height: 19px;
            background: #d9d9d9;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            outline: none;
            cursor: pointer;

            .icon {
              width: 7px;
              height: 7px;
            }
          }
        }
      }
    }
  }

  .disable {
    background-color: #f5f5f5;

    .select-field-additional-item__field__items-wrapper {
      .item {
        background-color: #f5f5f5;
      }
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 110%;
    max-height: 365px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    z-index: 9999;

    ul.list-container {
      max-height: 343px;
      display: flex;
      flex-direction: column;
      list-style: none;

      li {
        padding: 22px;
        border-top: 1px solid #e0e0e0;
      }
    }
  }
}
