@use '../../../scss/abstracts/' as *;

.select-field-airport-package {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    width: 100%;
    padding: 0 14px;
    height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;

    &__input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;
    }

    &__car {
      height: 100%;
      width: 100%;
      padding: 0 7px;
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: transparent;
      pointer-events: none;

      div:nth-child(1) {
        width: 30px;
        height: 30px;
        background-color: #e0e0e0;
        border-radius: 3px;
        overflow: hidden;
        pointer-events: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      div:nth-child(2) {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        pointer-events: none;

        h3 {
          font-size: 10px;
          font-weight: 700;
          text-transform: capitalize;
        }

        ul {
          display: flex;
          align-items: center;
          gap: 33px;

          li {
            font-size: 10px;
            font-weight: normal;
            text-transform: capitalize;
          }

          li:first-child {
            list-style: none;
          }
        }
      }

      span:last-child {
        margin-left: auto;
        color: #1c3d5d;
        font-size: $font-small;
        font-weight: 700;
      }
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 365px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;

    li {
      height: 89px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 21px;
      gap: 21px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      div:nth-child(1) {
        width: 61px;
        height: 61px;
        background-color: #e0e0e0;
        border-radius: 3px;
        overflow: hidden;
        pointer-events: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      div:nth-child(2) {
        height: 61px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        pointer-events: none;

        h3 {
          font-size: $font-small;
          font-weight: bold;
          text-transform: capitalize;
        }

        span:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 12px;

          p {
            font-size: $font-small;
            font-weight: normal;
            text-transform: capitalize;
          }

          span {
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background: #000;
          }
        }

        span:nth-child(3) {
          font-size: $font-small;
          font-weight: bold;
          color: #1c3d5d;
        }
      }
    }
  }
}
