@forward './VehicleDetail/vehicle-detail';
@forward './VehicleDetail/Checkbox/checkbox';

.vehicle {
  .button-wrapper {
    margin-bottom: 16px;
    .button-add {
      margin-left: auto;
      margin-right: 0;
    }
  }
  .tabs {
    .tab-list {
      &-item {
        display: inline-block;
        list-style: none;
        margin-bottom: -1px;
        padding: 0.5rem 0.75rem;
        cursor: pointer;
        padding: 15px 40px;
        border-radius: 5px 5px 0 0;
        margin-right: 8px;
        &:last-child {
          position: static;
        }
      }
    }
  }
  .g-table {
    min-height: 500px;
    position: relative;

    .empty {
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
