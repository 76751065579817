@use '../../../../../../scss/abstracts/' as *;

.vehicle-photos {
  width: 100%;
  background: white;
  margin-top: -15px;

  &_data {
    span {
      font-size: 12px;
      font-weight: 500;
      color: #9e9e9e;
    }

    .photos {
      margin-top: 22px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 28px 36px;
      margin-bottom: 36px;

      .identity-image {
        h3 {
          font-weight: bold;
          font-size: 12px;
          margin-bottom: 14px;
        }

        .image {
          width: 100%;
          height: 164px;
          background: white;
          border: 1px solid #e0e0e0;
          border-radius: 9px;
          padding: 12px;

          &-wrapper {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #e0e0e0;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              pointer-events: none;
            }
          }
        }
      }
    }

    .foul {
      margin-top: 36px;

      h3 {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 14px;
      }

      &__lists {
        width: 100%;
        display: flex;
        flex-direction: column;
        list-style: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;

        li {
          padding: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-weight: normal;
            font-size: 14px;
          }
        }

        li:nth-child(even) {
          background: white;
        }

        li:nth-child(odd) {
          background: #f7f7f7;
        }

        li:last-child {
          p {
            font-weight: bold;
          }
        }
      }
    }

    .action-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 48px;

      button {
        width: 252px;
      }
    }
  }

  &_empty-data {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 12px;
      color: #a8a8a8;
    }
  }
}
