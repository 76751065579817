@use '../../../scss/abstracts/' as *;

.violations {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  margin-bottom: 24px;
  gap: 14px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: $font-small;
      font-weight: 700;
    }

    &__add-violations {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;

      span {
        font-size: $font-small;
        font-weight: 400;
        color: $lightBlue;
      }
    }
  }

  &__lists {
    width: 100%;
    border-radius: 4px;
    background: #fff;
    overflow: hidden;
    border: 1px solid #d9d9d9;

    span {
      color: #a8a8a8;
      font-size: $font-small;
      font-weight: 400;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 60px;
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;

      li:nth-child(odd) {
        background-color: #f7f7f7;
      }

      li:nth-child(even) {
        background-color: white;
      }

      li:last-child {
        border-top: 1px solid #d9d9d9;
        background-color: #f7f7f7;
      }

      li {
        width: 100%;
        padding: 12px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div:first-child {
          font-size: $font-medium;
          font-weight: 400;
        }

        div:last-child {
          display: flex;
          align-items: center;
          gap: 35px;

          p {
            font-size: $font-medium;
            font-weight: 500;
          }

          .delete-sign {
            cursor: pointer;
            background-color: transparent;
          }

          .disable {
            pointer-events: none;
          }
        }
      }
    }
  }
}
