@use '../../../scss/abstracts/' as *;

.select-field-image {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h4 {
    display: block;
    font-size: $font-small;
    font-weight: 700;
  }

  &__wrapper {
    position: relative;
    z-index: 0;

    .input-field {
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border: none;
      outline: 1px solid #e0e0e0;
      font-size: $font-small;
      display: flex;
      align-items: center;
      padding: 0 12px;
      font-weight: 500;
      background-color: #fff;
      cursor: default;
    }

    .btn-upload {
      z-index: 10;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
      outline: none;
      border: none;
      font-size: $font-small;
      padding: 3px 12px;
      border-radius: 4px;
      background-color: #e0e0e0;

      &:hover {
        cursor: pointer;
      }
    }

    .btn-group {
      z-index: 10;
      display: flex;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 14px;
      gap: 6px;

      .btn-item {
        outline: none;
        border: none;
        font-size: $font-small;
        padding: 3px 12px;
        width: 76px;
        border-radius: 4px;

        &.btn-show {
          background-color: #009ef7;
          color: #fff;
        }
        &.btn-delete {
          background-color: #ea262626;
          color: #ea2626;
        }

        &.btn-show,
        &.btn-delete {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
