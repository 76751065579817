@use '../../../scss/abstracts/' as *;

.notificationForm {
  width: calc(361px - 48px);
  max-height: calc(406px - 36px);

  &__select-wrapper {
    position: relative;
    margin-bottom: 70px;

    .icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__select {
    background-color: #e5f3ff;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: none;
    outline: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;
    text-transform: capitalize;

    // custom arrow
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../../../public/assets/icon/chevron-down.svg');
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 50%;

    &:focus {
      outline-color: #0085ff;
    }

    option {
      font-size: 16px;
    }
  }

  &__button {
    .button {
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
}
