@use '../../../scss/abstracts/' as *;

.g-input {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .input-text {
    &-title {
      display: block;
      font-size: $font-small;
      font-weight: 700;
      // margin-bottom: 12px;
    }

    &-field {
      position: relative;
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border: none;
      outline: 1px solid #e0e0e0;
      font-size: $font-small;
      display: flex;
      align-items: center;
      padding: 0 12px;
      font-weight: 500;
      background-color: #fff;

      &::placeholder {
        font-size: 12px;
      }

      &:focus {
        outline-color: #0085ff;
      }

      &.with-icon {
        padding-right: 38px;
      }

      &.with-icon-left {
        padding-left: 38px;
      }

      &.with-currency {
        padding-left: 48px;
      }

      &.disable,
      &:disabled {
        pointer-events: none;
        background-color: #f7f7f7;
      }

      &.read-only {
        outline: 1px solid #e0e0e0;
        cursor: default;
      }
    }
  }

  .field-wrapper {
    position: relative;

    .see-password {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
      text-transform: capitalize;

      option {
        height: 40px;
      }
    }

    &.with-icon {
      display: flex;
      width: 100%;
    }

    .input-icon {
      width: 18px;
      height: auto;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);

      &.password {
        cursor: pointer;
      }
    }

    .input-icon-left {
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      z-index: 10;
    }

    .input-price {
      font-size: $font-small;
      padding-right: 14px;
      height: auto;
      // border-right: 1px solid #e0e0e0;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
