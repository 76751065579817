@use '../../../scss/abstracts/' as *;

.select {
  &-driver-checkbox {
    position: relative;

    .input {
      &-title {
        display: block;
        font-size: $font-small;
        font-weight: 700;
        margin-bottom: 12px;
      }
    }
  }

  &-input-field {
    position: relative;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: none;
    outline: 1px solid #e0e0e0;
    font-size: $font-small;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0;

    // .icon {
    //   position: absolute;
    //   top: 50%;
    //   right: 12px;
    //   transform: translateY(-50%);
    //   cursor: pointer;
    // }

    .icon-wrapper {
      width: 10%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .input-value {
      padding: 0 14px;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
    }

    .seleceted-drivers {
      width: 90%;
      height: 100%;
      padding: 14px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      cursor: pointer;

      scrollbar-width: 40px;
      scrollbar-color: #e0e0e0;

      /* Menyelaraskan tampilan scrollbar */
      &::-webkit-scrollbar {
        height: 4px; /* Tinggi scrollbar horizontal */
        width: 40px;
      }

      &::-webkit-scrollbar-track {
        background: transparent; /* Warna track scrollbar */
        margin-right: 20px;
        margin-left: 20px;
      }

      &::-webkit-scrollbar-thumb {
        background: #e0e0e0; /* Warna thumb scrollbar */
        border-radius: 10px; /* Radius untuk sudut bulat */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
      }

      // for Firefox
      &::-moz-scrollbar {
        width: 40px;
        height: 4px;
      }

      &::-moz-scrollbar-track {
        background: transparent; /* Warna track scrollbar */
        margin-right: 20px;
        margin-left: 20px;
      }

      &::-moz-scrollbar-thumb {
        background: #e0e0e0; /* Warna thumb scrollbar */
        border-radius: 10px; /* Radius untuk sudut bulat */
      }

      &::-moz-scrollbar-thumb:hover {
        background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
      }

      .wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        list-style: none;

        div {
          margin-right: 20px;
          height: 28px;
          background-color: #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          padding: 0 20px;
          font-size: 10px;
        }
      }

      p {
        color: gray;
      }
    }

    &.focus {
      outline-color: #0085ff;
    }

    &.disabled {
      background-color: #efefef4d;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }

    &__dropdown {
      margin-top: 6px;
      max-height: 188px;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: auto;
      list-style-type: none;
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      z-index: 10;
      overflow-y: auto;
    }

    &__item {
      padding: 16px;
      font-size: $font-small;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
}
