@use '../../../../../scss/abstracts/' as *;

.promo-detail-component {
  min-height: 950px;

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }
}
