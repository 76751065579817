@use '../../../../scss/abstracts/' as *;
.detail-order {
  &__back-btn {
    display: flex;
    width: fit-content;
    margin-bottom: 21px;

    &:hover {
      cursor: pointer;
    }

    p {
      margin-left: 12px;
      font-size: $font-medium;
    }
  }

  &__input-additional {
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 28px;
    margin-bottom: 42px;
  }

  &__total-price {
    background-color: #e7f3ff;
    width: 396px;
    margin-left: auto;
    margin-right: 0;
    border-radius: 5px;
    padding: 14px;

    .total-price-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3,
      p {
        font-size: 14px;
        font-weight: 700;
      }

      p {
        color: #1c3d5d;
      }
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__return-detail,
  &__garage-detail,
  &__referral-code,
  // &__price-detail,
  &__price-detail-payment-type,
  &__voucher-detail {
    &-title {
      margin-bottom: 18px;

      h1 {
        color: $lightBlue;
        font-size: $font-label;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: flex-end;

      &__rent-date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }

      &__phone-number {
        align-items: flex-end;
        gap: 12px;
        display: grid;
        grid-template-columns: 20% auto;

        &-wrapper {
          position: relative;
        }
      }

      &__image-wrapper {
        position: absolute;
        right: 0;
        top: 0;
      }

      &__payment-method {
        &.confirmation {
          display: grid;
          grid-template-columns: auto 30%;
          align-items: flex-end;
          gap: 12px;
        }

        &.not-confirmation {
          display: block;
        }
      }

      &__preview-image {
        position: relative;

        .preview-image-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .preview-image-input {
          .input-text-field {
            padding-right: 100px;
          }
        }

        .validation-identity-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .reupload-icon {
          position: absolute;
          top: 5px;
          right: 8px;
          display: flex;
          align-items: center;
          column-gap: 6px;
          font-size: 12px;
          color: #f1a33a;
        }
      }

      &__input-violation {
        margin-bottom: 28px;
      }

      &__input-additional {
        margin-bottom: 28px;
      }

      &__input-violation,
      &__payment-type {
        margin-top: 28px;
      }
    }
  }

  &__price-detail-title {
    margin-bottom: 18px;

    h1 {
      color: $lightBlue;
      font-size: $font-label;
    }
  }

  &__price-detail-body__payment-type &__price-detail-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
    align-items: flex-end;
  }

  &__price-detail-body__payment-total {
    margin-top: 28px;

    .detail-order__price-detail-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: flex-end;
    }
  }

  &__price-detail-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
    align-items: flex-end;

    &__rent-date {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    &__phone-number {
      align-items: flex-end;
      gap: 12px;
      display: grid;
      grid-template-columns: 20% auto;

      &-wrapper {
        position: relative;
      }
    }

    &__image-wrapper {
      position: absolute;
      right: 0;
      top: 0;
    }

    &__payment-method {
      &.confirmation {
        display: grid;
        grid-template-columns: auto 30%;
        align-items: flex-end;
        gap: 12px;
      }

      &.not-confirmation {
        display: block;
      }
    }

    &__preview-image {
      position: relative;

      .preview-image-btn {
        position: absolute;
        bottom: 5px;
        right: 8px;
      }

      .preview-image-input {
        .input-text-field {
          padding-right: 100px;
        }
      }
    }

    &__input-violation {
      margin-bottom: 28px;
    }

    &__input-additional {
      margin-bottom: 28px;
    }

    &__input-violation,
    &__payment-type {
      margin-top: 28px;
    }
  }

  &__price-detail-body-with-driver {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
    align-items: flex-end;

    &__rent-date {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    &__phone-number {
      align-items: flex-end;
      gap: 12px;
      display: grid;
      grid-template-columns: 20% auto;

      &-wrapper {
        position: relative;
      }
    }

    &__image-wrapper {
      position: absolute;
      right: 0;
      top: 0;
    }

    &__payment-method {
      &.confirmation {
        display: grid;
        grid-template-columns: auto 30%;
        align-items: flex-end;
        gap: 12px;
      }

      &.not-confirmation {
        display: block;
      }
    }

    &__preview-image {
      position: relative;

      .preview-image-btn {
        position: absolute;
        bottom: 5px;
        right: 8px;
      }

      .preview-image-input {
        .input-text-field {
          padding-right: 100px;
        }
      }

      .validation-identity-btn {
        position: absolute;
        bottom: 5px;
        right: 8px;
      }

      .reupload-icon {
        position: absolute;
        top: 5px;
        right: 8px;
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: 12px;
        color: #f1a33a;
      }
    }

    &__input-violation,
    &__payment-type {
      margin-top: 28px;
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__return-detail,
  &__referral-code,
  &__price-detail,
  &__voucher-detail {
    margin-bottom: 42px;
  }

  &__renter-detail {
    margin-top: 28px;
  }

  &__transaction-buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }

      &.btn-cancel {
        color: #ff0000;
        outline-color: #ff0000;

        &:hover {
          background-color: #ff0000;
          color: #f7f7f7;
        }
      }
    }
  }

  &__select-driver {
    margin-bottom: 35px;
  }

  // &__payment-detail {
  //   &-body {
  //     &__select-driver {
  //       grid-column: 2;
  //     }
  //   }
  // }

  &__additional-item {
    width: 100%;
    margin-bottom: 36px;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    div.wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 38px;
      column-gap: 28px;

      .additional {
        grid-column: 1 / 3;
      }

      // .input-text-area-title {
      //   margin-bottom: 4px;
      // }
    }
  }

  .assign-driver-input {
    .input-text-field:hover {
      cursor: pointer;
    }
  }

  .input-text-field:disabled {
    background-color: #f7f7f7;
  }

  .tab-list {
    border-bottom: 1px solid #e0e0e0;
  }

  .tab-list-item {
    border: 1px solid #e0e0e0;
  }
}
