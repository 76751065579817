@use '../../../scss/abstracts/' as *;

.notification {
  &-modal {
    width: 361px;
    max-height: 567px;

    textarea {
      resize: none;
      width: 100%;
      height: 210px;
      border-radius: 6px;
      border: 1px solid #dbdbdb;
      padding: 9px 12px;
      outline: none;
      font-size: $font-small;
    }

    h3 {
      font-size: $font-small;
      margin-bottom: 12px;
    }
  }

  &__select-wrapper {
    position: relative;
    margin-bottom: 28px;

    .icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__select {
    background-color: #e5f3ff;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: none;
    outline: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;
    text-transform: capitalize;

    // custom arrow
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../../../public/assets/icon/chevron-down.svg');
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 50%;

    &:focus {
      outline-color: #0085ff;
    }

    option {
      font-size: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    font-size: 16px;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    text-transform: capitalize;

    &-item {
      cursor: pointer;
      padding: 5px;

      &:hover {
        background-color: #e5f3ff;
      }
    }
  }

  // &-input {
  //   margin-bottom: 14px;
  //   min-height: 210px;
  // }

  &-methods {
    .method {
      display: flex;
      align-items: center;
      width: 100%;
      outline: 1px solid #dbdbdb;
      padding: 14px 26px;
      border: none;
      border-radius: 6px;
      background: none;
      font-size: $font-medium;
      margin-bottom: 16px;
      transition: 0.2s background-color;

      &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 18px;
      }

      .icon {
        width: 25px;

        svg {
          display: block;
        }
      }

      span {
        margin-left: 18px;
        font-weight: 500;
      }

      &.active {
        outline-color: #0085ff;
        background-color: #e5f3ff;
        outline-width: 2px;
      }
    }
  }

  &-buttons {
    .button {
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
}
