@use '../../scss/abstracts/' as *;

.login-page {
  height: 100vh;
  max-width: 100vw;
  background-color: $darkStone;
  position: relative;

  .vector-image {
    max-width: 100%;
    height: auto;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
