.zone-input-card {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 24px;
  position: relative;

  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    position: relative;

    .input-group__image-input {
      h4 {
        font-size: 12px;
        margin-bottom: 12px;
        line-height: 18px;
      }

      .image-upload {
        display: flex;
        align-items: center;
        padding: 0 14px;
        border-radius: 5px;
        background-color: white;
        outline: 1px solid #e0e0e0;
        column-gap: 12px;
        min-height: 44px;

        .btn-upload {
          font-size: 12px;
          outline: none;
          border: none;
          padding: 5px 14px;
          border-radius: 4px;
          transition: 0.2s;
          background-color: #e0e0e0;

          &:disabled:hover {
            background-color: #e0e0e0;
            cursor: default;
          }

          &:hover {
            background-color: #f0f0f0;
            cursor: pointer;
          }
        }

        .link-upload {
          font-size: 12px;
          color: #0085ff;
          transition: 0.2s;

          &:hover {
            color: #006dd3;
            cursor: pointer;
          }
        }
      }
    }
  }

  .g-input {
    margin-bottom: 24px;
  }

  &__add-list {
    &-zone,
    &-vehicle-price {
      border-radius: 100%;
      padding: 0;
      min-width: 0;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__remove-btn {
    position: absolute;
    right: -12px;
    top: -12px;
    width: 28px;
    height: 28px;

    svg {
      width: 100%;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__vehicle-price {
    margin-bottom: 12px;

    .vehicle-price__buttons {
      position: absolute;
      right: 10px;
      top: 40%;
      display: inline-flex;
      column-gap: 4px;

      svg:hover {
        cursor: pointer;
      }
    }
  }

  &__divider {
    border: 1px solid #bbbbbb;
    border-style: dashed;
    height: 1px;
    width: 100%;
    position: relative;
  }

  &__list-zone {
    margin-top: 40px;
    margin-bottom: 40px;

    .input-text-title {
      display: block;
      font-size: 0.75rem;
      font-weight: 700;
      margin-bottom: 12px;
    }

    .list-zone {
      position: relative;
    }

    .g-input {
      margin-bottom: 14px;
    }

    .list-zone__buttons {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: inline-flex;
      column-gap: 4px;

      svg:hover {
        cursor: pointer;
      }
    }
  }

  &__save-btn {
    margin-top: 50px;
    margin-left: auto;
    margin-right: 0;
    width: fit-content;
  }

  &__edit-btn {
    margin-left: auto;
    margin-right: 0;
  }
}
