@use '../../../scss/abstracts/' as *;

.select-field-shuttle {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    gap: 13px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    padding: 0 12px;
    min-height: 46px;

    &__input {
      padding: 14px 0;
      border: none;
      outline: none;
      font-weight: bold;
      text-transform: capitalize;
      font-size: $font-small;
      cursor: default;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__input:focus & {
      outline-color: #0085ff;
    }

    .placeholder {
      color: #757575;
      font-weight: 400;
    }

    span {
      font-size: $font-small;
      font-weight: 400;
      color: #1c3d5d;
      pointer-events: none;
    }

    &__list {
      width: 97%;
      background: transparent;
      cursor: default;
      font-size: $font-small;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 44px;

      span {
        height: 28px;
        font-size: 10px;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        padding: 0 20px;
      }
    }

    &__search {
      width: 100%;
      padding: 14px 0;
      border: none;
      outline: none;
      text-transform: capitalize;
      font-size: $font-small;
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    // top: 84px;
    max-height: 176px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;
    cursor: pointer;

    li:nth-child(1) {
      border-top: none;
    }

    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 11px 14px;
      border-top: 1px solid #e0e0e0;
      background-color: white;

      span:nth-child(1) {
        font-weight: 700;
        font-size: 12px;
        color: black;
        pointer-events: none;
      }

      span:nth-child(2) {
        font-weight: 400;
        font-size: 12px;
        color: #1c3d5d;
        pointer-events: none;
      }
    }

    li.list-shuttle:hover {
      filter: brightness(95%);
    }

    li.list-shuttle {
      flex-direction: column;
      cursor: pointer;
    }

    li.list-shuttle-check {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        display: block;
      }

      div {
        width: 18px;
        height: 18px;
        background: #fff;
        border: 1px solid #d9d9d9;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        input[type='checkbox'] {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          cursor: pointer;
          opacity: 0;
        }
      }

      .check {
        background: #0085ff;
      }
    }
  }
}
