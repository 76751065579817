@use '../../../../scss/abstracts/' as *;

.task-admin {
  &__wrapper {
    position: relative;
    background-color: $white;
    border-radius: 4px;
    font-family: $inter;
    min-width: 300px;
    padding: 18px 16px;
    min-height: 500px;
  }

  &-detail__back-btn {
    display: flex;
    width: fit-content;
    margin-bottom: 21px;

    &:hover {
      cursor: pointer;
    }

    p {
      margin-left: 12px;
      font-size: $font-medium;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  .input-text-field:disabled {
    background-color: #f7f7f7;
  }

  .tab-list {
    border-bottom: 1px solid #e0e0e0;
  }

  .tab-list-item {
    border: 1px solid #e0e0e0;
  }
}
