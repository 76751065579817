@use '../../../scss/abstracts/' as *;

.contact-detail {
  display: flex;
  min-height: 274px;
  align-items: stretch;
  gap: 92px;
  min-width: 782px;
  margin-top: 25px;

  &__left,
  &__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right {
    .text-area-input {
      textarea {
        height: 247px;
      }
    }
  }
}
