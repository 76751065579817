@use '../../../../../scss/abstracts/' as *;

.overtime-rules {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  column-gap: 23px;
  row-gap: 36px;
  margin-top: 24px;
  padding-bottom: 50px;

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__overtime-charge,
  &__overtime-limit {
    h3 {
      color: #000;
      font-size: 12px;
      font-weight: 700;
    }
  }

  &__overtime-limit-wrapper {
    display: flex;
  }

  &__condition {
    display: flex;
    flex: 1;
  }

  &__select-container {
    position: relative;
    width: 100%;
    display: inline-block;
    align-self: flex-end;

    .options-container {
      display: block;
    }
  }

  &__select-label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 16px;
  }

  &__select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
  }

  &__options-container {
    display: none;
    margin-top: 4px;
    width: 100%;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;

    .option {
      padding: 10px;
      border-top: 1px solid #e0e0e0;
      cursor: pointer;
      background-color: white;

      &:hover {
        filter: brightness(95%);
      }

      &:nth-child(1) {
        border-top: none;
      }
    }

    .option div {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .option span {
      display: inline-flex;
      margin-right: 2px;
    }

    &.show {
      display: block;
    }
  }

  &__arrow {
    transform: rotate(0deg);
    transition: all ease 0.3s;
  }

  .arrow-down {
    transform: rotate(-180deg);
  }

  &__time {
    flex: 1;
    margin-left: 14px;
  }

  &__radio-group {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__btn-group {
    margin-top: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}
