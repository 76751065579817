@use '../../../scss/abstracts/' as *;

.add-brand-car {
  width: 568px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 16px;

  &__button-field {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
