@use '../../../../../../scss/abstracts/' as *;

.detail-payment {
  background: white;
  padding: 18px 16px;
  border-radius: 4px;

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $lightBlue;
  }

  &__bill-list {
    display: flex;
    margin-top: 21px;
    padding-bottom: 37px;
    border-bottom: 1px solid #dbdbdb;

    div:nth-child(1) {
      padding-right: 24px;
      flex-grow: 1;
    }

    div:nth-child(2) {
      border-right: 1px dashed #dbdbdb;
      border-left: 1px dashed #dbdbdb;
      flex-grow: 1;

      ul {
        li.total-additional-item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          font-size: $font-small;
          font-weight: normal;

          span:last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
      }
    }

    div:nth-child(3) {
      flex-grow: 1;
    }

    div:last-child {
      padding-left: 24px;
      flex-grow: 1;
    }

    div {
      min-height: 226px;

      h3 {
        margin-bottom: 17px;
        font-size: $font-small;
        font-weight: bold;
        color: #000;
      }

      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 17px;
        list-style: none;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: $font-small;
          font-weight: normal;

          span.booking-zones {
            display: flex;
            align-items: flex-start;
            column-gap: 4px;

            span:first-child {
              width: 50%;
            }

            span:last-child {
              display: flex;
              flex-direction: column;

              p:first-child,
              p:nth-child(3) {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  &__action-button {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__total-full {
      margin-bottom: 8px;
    }

    &__total-full,
    &__total-half {
      background: #e7f3ff;
      border-radius: 5px;
      padding: 14px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 139px;
      min-width: 396px;

      p {
        font-size: 14px;
        font-weight: bold;
      }

      span {
        color: #1c3d5d;
        font-size: 14px;
        font-weight: bold;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 35px;
    }
  }
}
