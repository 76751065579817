@use '../../../scss/abstracts/' as *;

.reject-order {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  max-width: 684px;
  min-height: 392px;
  padding: 24px;
  border-radius: 10px;
  z-index: $modal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;

  h3 {
    font-size: 16px;
    font-weight: 700;
  }

  &__message {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__textarea {
    width: 636px;
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
