@use '../../../../scss/abstracts/' as *;

.list-additional {
  display: flex;
  column-gap: 22px;

  &__image {
    position: relative;
    width: 88px;
    height: 88px;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      position: absolute;
      bottom: 8px;
      right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      background: white;
      cursor: pointer;
    }
  }

  &__info-item {
    h3 {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .price {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .stock {
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .description {
      width: 100%;
      max-height: 44px;
      font-size: 8px;
      font-weight: 400;
      margin-bottom: 16px;
      overflow: auto;

      /* Sembunyikan scrollbar di Chrome, Safari, dan Edge */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      p {
        max-width: 572px;
      }
    }

    .description::-webkit-scrollbar {
      display: none; /* Sembunyikan scrollbar di WebKit */
    }

    .colors {
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-bottom: 19px;

      p {
        font-size: 8px;
        font-weight: 700;
      }

      &__button {
        display: flex;
        align-items: center;
        column-gap: 6px;

        button {
          min-width: 55px;
          height: 20px;
          font-size: 8px;
          border-radius: 4px;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 0 8px;
        }

        button:hover {
          background: #f1a33a;
          border: none;
          outline: none;
          color: white;
        }

        .not-active {
          background-color: white;
          border: 1px solid #dbdbdb;
        }

        .active {
          background: #f1a33a;
          border: 1px solid #f1a33a;
          outline: none;
          color: white;
        }

        .disbale {
          background-color: #eaeaea;
          cursor: not-allowed;
        }
      }
    }

    .total {
      display: flex;
      align-items: center;
      column-gap: 28px;

      p {
        font-size: 8px;
        font-weight: 700;
      }

      &__button {
        display: flex;
        align-items: center;
        column-gap: 10px;

        span:nth-child(2) {
          pointer-events: none;
        }

        .icon {
          background-color: transparent;
          border: none;
          cursor: pointer;

          .button-icon {
            background-color: transparent;
            color: #1c3d5d;
          }
        }

        .disable {
          background-color: transparent;
          cursor: not-allowed;

          .button-icon {
            color: #eaeaea;
          }
        }
      }
    }
  }
}
