@use '../../../../../../scss/abstracts/' as *;

.content--right {
  width: 50%;

  &__upload {
    min-height: 331px;
    background-color: $white;
    border-radius: 4px;

    p {
      margin-bottom: 12px;
      font-size: $font-small;
    }
  }

  &__button {
    .button {
      margin-left: auto;
      margin-right: 0;
      font-size: $font-small;
      margin-top: 26px;
    }
  }
}
