@use '../../../scss/abstracts/' as *;

.select-field-duration {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    gap: 12.5px;
    padding: 0 12px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;

    &__input {
      width: 97%;
      border: none;
      outline: none;
      height: 44px;
      font-size: $font-small;
      background: transparent;
      cursor: default;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__input:focus & {
      outline-color: #0085ff;
    }
  }

  .disable {
    background-color: #f5f5f5;
    pointer-events: none;
  }

  &__dropdown {
    position: absolute;
    padding: 12px 24px;
    right: 0;
    left: 0;
    top: 85px;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(35, 32, 32, 0.1);
    border-radius: 5px;
    z-index: 10;

    &__container {
      font-size: $font-small;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        color: #000;
        font-size: 12px;
        font-weight: 700;
      }

      .duration-list {
        width: 100%;

        ul {
          width: 100%;
          height: 189px;
          display: flex;
          flex-direction: column;
          list-style: none;
          overflow-y: scroll;
          padding: 12px 0px;
          border-radius: 3px;
          border: 1px solid #e0e0e0;

          li {
            width: 100%;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            background-color: white;
            cursor: pointer;

            p {
              font-size: 12px;
              font-weight: 400;

              span {
                font-weight: 700;
              }
            }
          }

          li:hover {
            background-color: #f5f6fa;
          }

          .active {
            background-color: #f5f6fa;
          }
        }
      }

      .selesai {
        width: 100%;
        text-align: right;
        color: #f1a33a;

        p {
          cursor: pointer;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}
