@use '../../../../scss/abstracts/' as *;

.deposit {
  &__wrapper {
    background-color: $white;
    border-radius: 4px;
    font-family: $inter;
    min-width: 300px;
    padding: 18px 16px;
    min-height: 85vh;

    .order-key-link {
      color: #0084ff;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  &__filter {
    display: flex;
    column-gap: 14px;
    align-items: center;

    .download-icon,
    .upload-icon {
      margin-right: 6px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      font-size: 14px;
      margin-left: 8px;
    }
  }
}
