@use '../../../../../../scss/abstracts/' as *;

.upload-image {
  width: 171px;
  height: 198px;
  border-radius: 3px;
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 8px;

  div.wrapper {
    width: 100%;
    height: 155px;
    border-radius: 3px;
    border: 1px dashed rgba(224, 224, 224, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;

    .icon {
      width: 24px;
      height: 24px;
    }

    p {
      width: 100px;
      font-size: 12px;
      font-weight: 400px;
      text-align: center;

      span {
        color: $lightBlue;
        font-weight: 700;
        cursor: pointer;
      }

      span:hover {
        text-decoration: underline;
      }
    }
  }

  .active {
    background: #eaeaea;
  }
}
