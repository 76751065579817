@use '../../../../../scss/abstracts/' as *;

.table-point {
  width: 100%;

  thead {
    th {
      text-align: left;
      padding: 0 0 24px;
      font-size: 12px;
      font-weight: 700;
    }

    th:first-child,
    th:last-child {
      width: 10%;
    }

    th:nth-child(2) {
      width: 15%;
    }

    th:nth-child(3) {
      width: 25%;
    }

    th:nth-child(4),
    th:nth-child(5) {
      width: 20%;
    }
  }

  tbody {
    tr {
      td {
        font-size: 12px;
        font-weight: 400;
        padding: 12px 0;
      }

      td:first-child {
        div {
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
        }
      }
    }
  }
}

.table-point-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &__head {
    width: 100%;
    column-gap: 20px;

    div {
      height: 30px;
      background: #fafafa;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    div {
      height: 30px;
      background: #fafafa;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
