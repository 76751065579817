@use '../../../../scss/abstracts/' as *;

.task-detail {
  &__back-btn {
    display: flex;
    width: fit-content;
    margin-bottom: 21px;

    &:hover {
      cursor: pointer;
    }

    p {
      margin-left: 12px;
      font-size: $font-medium;
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__return-detail,
  &__garage-detail,
  &__price-detail {
    &-title {
      margin-bottom: 18px;

      h1 {
        color: #000;
        font-size: $font-label;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: flex-end;

      &__rent-date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }

      &__phone-number {
        align-items: flex-end;
        gap: 12px;
        display: grid;
        grid-template-columns: 20% auto;

        &-wrapper {
          position: relative;
        }
      }

      // &__image-wrapper {
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      // }

      &__payment-method {
        &.confirmation {
          display: grid;
          grid-template-columns: auto 30%;
          align-items: flex-end;
          gap: 12px;
        }

        &.not-confirmation {
          display: block;
        }
      }

      // &__preview-image {
      //   position: relative;

      //   .preview-image-btn {
      //     position: absolute;
      //     bottom: 5px;
      //     right: 8px;
      //   }

      //   .preview-image-input {
      //     .input-text-field {
      //       padding-right: 100px;
      //     }
      //   }
      // }

      // &__input-violation,
      // &__payment-type {
      //   margin-top: 28px;
      // }
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__return-detail,
  &__price-detail {
    margin-bottom: 42px;
  }

  &__renter-detail {
    margin-top: 28px;
  }

  &__transaction-buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }

  &__identity {
    display: flex;
    align-items: center;
    gap: 28px;
    width: 100%;

    &--title {
      margin-bottom: 14px;

      h4 {
        font-size: 12px;
      }
    }
  }

  &__image-container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 12px;
    border-radius: 9px;
    position: relative;
    height: 164px;
    background-color: white;
    width: 100%;

    img {
      width: 100%;
      height: 140px;
      object-fit: cover;
      border-radius: 9px;
    }
  }

  &__task-information-section,
  &__task-information-empty {
    background-color: #fff;
    padding: 18px 16px;
    // margin-top: 32px;
    border-radius: 6px;

    &__date {
      font-size: 12px;
      margin-top: 6px;
      color: #9e9e9e;
    }
  }

  // &__with-driver-information-list {
  //   padding: 0 16px;
  // }

  &__line {
    width: calc(100% - 32px);
    margin: 0 auto;
    height: 47px;
    border-bottom: 1px solid #e0e0e0;
    padding: 0 14px;
    // position: absolute;
  }

  &__task-information-empty {
    position: relative;
    height: 215px;

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    p {
      font-size: 12px;
      text-align: center;
      color: #a8a8a8;
    }
  }

  &__foto-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
    width: 100%;
    margin-top: 22px;
  }

  &__note {
    margin-top: 36px;

    &__title {
      font-size: 12px;
    }

    &__box {
      border: 1px solid #d9d9d9;
      padding: 12px;
      border-radius: 4px;
      margin-top: 14px;
      background-color: #f7f7f7;

      p {
        font-size: 12px;
      }
    }
  }

  &__violations {
    margin-top: 36px;

    &__title {
      font-size: 12px;
    }

    &__box {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-top: 14px;
    }

    &__violation-list {
      li {
        list-style: none;
      }
    }

    &__violation-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      font-weight: 500;

      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
    }

    &__violation-total {
      border-top: 1px solid #d9d9d9;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
    }
  }

  &__booking-zone__wrapper {
    // border: 0.5px solid #e0e0e0;
    background: #fff;
    padding: 20px 14px;
    margin-top: 32px;
    border-radius: 6px;
  }

  &__booking-zone__form {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  &__booking-zone__back-btn-wrapper {
    display: flex;
    padding: 48px 0;
    justify-content: end;

    &__without-driver {
      background-color: #fff;
      padding-right: 14px;
    }
  }

  &__list-booking-zone {
    width: 100%;
    border: 0.5px solid #e0e0e0;
    // background: #fff;

    .header {
      width: 100%;
      padding: 20px 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid #e0e0e0;
      cursor: pointer;

      &__date {
        display: flex;
        align-items: center;
        gap: 24px;

        div:first-child {
          display: flex;
          align-items: center;
          gap: 12px;

          span {
            font-size: 14px;
            font-weight: 400;
          }
        }

        div:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 12px;
          font-size: 14px;
          font-weight: 700;

          span {
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background-color: #000;
          }
        }

        .selected-zone {
          width: 53px;
          font-size: 10px;
          font-weight: 400;
          padding: 6.5px 10px;
          border-radius: 4px;
        }

        .selected-zone-price {
          color: #299b0a;
          font-size: 14px;
          font-weight: 600;
        }

        .zone-zero {
          color: #1c3d5d;
          background-color: #e3f1ff;
        }

        .zone-one {
          color: #dd7f00;
          background-color: #ffefd9;
        }

        .zone-two {
          color: #e5602f;
          background-color: #ffebe3;
        }
      }

      .chevron {
        transition: all 0.3s ease;
      }

      .up {
        transform: rotate(-180deg);
      }

      .down {
        transform: rotate(0);
      }
    }

    .form {
      overflow: hidden;
      transition: all 0.3s ease;

      &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
      }

      &__input-data {
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        row-gap: 28px;
        column-gap: 14px;
        padding: 16px 24px;

        .end-time {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &__zone-list {
        &__desc {
          list-style: none;

          li {
            display: flex;
            flex-direction: column;
            gap: 12px;
            overflow: hidden;
            position: relative;
          }

          li:first-child {
            border: none;

            h3 {
              color: #000;
              font-size: 12px;
              font-weight: 700;
            }

            .head {
              display: flex;
              align-items: center;
              gap: 8px;

              p {
                color: #000;
                font-size: 12px;
                font-weight: 400;
                span {
                  font-weight: 700;
                }
              }

              div {
                width: 73px;
                height: 21px;
                border: 1px solid #1c3d5d;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1c3d5d;
                font-size: 10px;
                font-weight: 400;
              }
            }

            .notice {
              width: 232px;
              padding: 10px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: #e3f1ff;
              color: #1c3d5d;
              font-size: 10px;
              font-weight: 400;
            }

            .places {
              width: 55%;
              color: #000;
              font-size: 12px;
              font-weight: 400;
              line-height: 26px;
            }
          }

          li:nth-child(2) {
            border-top: 1px dashed #e0e0e0;
            border-bottom: 1px dashed #e0e0e0;

            .head {
              display: flex;
              align-items: center;
              gap: 8px;

              p {
                color: #000;
                font-size: 12px;
                font-weight: 400;
                span {
                  font-weight: 700;
                }
              }

              div {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-left: 3px;
                cursor: pointer;

                span {
                  color: #0085ff;
                  font-size: 10px;
                  font-weight: 400;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            .notice {
              width: 232px;
              padding: 10px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: #ffefd9;
              color: #dd7f00;
              font-size: 10px;
              font-weight: 400;
            }

            .places {
              width: 55%;
              color: #000;
              font-size: 12px;
              font-weight: 400;
            }

            .x-mark {
              position: absolute;
              right: 48px;
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              background-color: #eee;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #000;
              border-radius: 100%;
              font-size: 12px;
              cursor: pointer;
            }
          }

          li:last-child {
            border: none;

            .head {
              display: flex;
              align-items: center;
              gap: 8px;

              p {
                color: #000;
                font-size: 12px;
                font-weight: 400;
                span {
                  font-weight: 700;
                }
              }

              div {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-left: 3px;
                cursor: pointer;

                span {
                  color: #0085ff;
                  font-size: 10px;
                  font-weight: 400;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }

            .notice {
              width: 280px;
              padding: 10px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: #ffebe3;
              color: #e5602f;
              font-size: 10px;
              font-weight: 400;
            }

            .places {
              width: 50%;
              color: #000;
              font-size: 12px;
              font-weight: 400;
              line-height: 26px;
            }

            .x-mark {
              position: absolute;
              right: 48px;
              top: 50%;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              background-color: #eee;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #000;
              border-radius: 100%;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }

      // &__stay-question {
      //   width: 100%;
      //   background: #e7f3ff;
      //   display: flex;
      //   align-items: center;
      //   gap: 8px;
      //   color: #1c3d5d;
      //   font-size: 12px;
      //   font-weight: 500;
      //   padding: 14px;
      //   border-top: 1px dashed #e0e0e0;
      // }

      &__total-price-zone {
        padding: 10px 14px;
        font-size: 12px;
        font-style: normal;
        color: #000;
        font-weight: 400;

        span {
          color: #299b0a;
          font-weight: 700;
        }
      }
    }
  }
}
