@use '../../../../../../scss/abstracts/' as *;

.additional-color-variation {
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 12px;
  padding: 36px 20px 60px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 28px;

    li {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 36px;
      column-gap: 23px;

      div.stock-and-quantity {
        display: flex;
        align-items: center;
        column-gap: 14px;

        div {
          width: 100%;
        }
      }

      div.images {
        h3 {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 14px;
        }

        div.list-container {
          height: 224px;
          overflow-x: auto;
          overflow-y: hidden;
          scrollbar-width: 40px;
          scrollbar-color: #e0e0e0;

          /* Menyelaraskan tampilan scrollbar */
          &::-webkit-scrollbar {
            height: 4px; /* Tinggi scrollbar horizontal */
            width: 40px;
          }

          &::-webkit-scrollbar-track {
            background: transparent; /* Warna track scrollbar */
          }

          &::-webkit-scrollbar-thumb {
            background: #e0e0e0; /* Warna thumb scrollbar */
            border-radius: 10px; /* Radius untuk sudut bulat */
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
          }

          // for Firefox
          &::-moz-scrollbar {
            width: 40px;
            height: 4px;
          }

          &::-moz-scrollbar-track {
            background: transparent; /* Warna track scrollbar */
          }

          &::-moz-scrollbar-thumb {
            background: #e0e0e0; /* Warna thumb scrollbar */
            border-radius: 10px; /* Radius untuk sudut bulat */
          }

          &::-moz-scrollbar-thumb:hover {
            background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
          }

          div.image-list {
            display: flex;
            flex-wrap: nowrap;
            width: max-content;
            padding-bottom: 26px;
            padding-right: 4px;
            column-gap: 20px;

            div.upload-image {
              width: 171px;
              height: 198px;
              border-radius: 3px;
              border: 1px solid rgba(224, 224, 224, 1);
              padding: 8px;

              div.wrapper {
                width: 100%;
                height: 155px;
                border-radius: 3px;
                border: 1px dashed rgba(224, 224, 224, 1);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 10px;

                .icon {
                  width: 24px;
                  height: 24px;
                }

                p {
                  width: 100px;
                  font-size: 12px;
                  font-weight: 400px;
                  text-align: center;

                  span {
                    color: $lightBlue;
                    font-weight: 700;
                    cursor: pointer;
                  }

                  span:hover {
                    text-decoration: underline;
                  }
                }
              }

              .active {
                background: #eaeaea;
              }
            }

            div.uploaded-image {
              width: 171px;
              height: 198px;
              border-radius: 3px;
              border: 1px solid rgba(224, 224, 224, 1);
              position: relative;
              padding: 8px;
              display: flex;
              flex-direction: column;
              row-gap: 6px;

              div.image-wrapper {
                height: 155px;
                width: 100%;
                border-radius: 3px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 200px;
                  object-position: center;
                  object-fit: cover;
                }
              }

              div.icon-wrapper {
                width: 100%;
                height: 25px;
                position: relative;

                .icon {
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 25px;
                  height: 25px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .add-button {
        grid-column-start: 1;
        grid-column-end: 3;
        position: relative;

        .line {
          width: 100%;
          border: 1px dashed rgba(187, 187, 187, 1);
        }

        .circle {
          position: absolute;
          top: -22px;
          left: 50%;
          transform: translateX(-50%);
          width: 46px;
          height: 46px;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;

          div {
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background: $lightBlue;
            cursor: pointer;
            overflow: hidden;
          }
        }
      }
    }
  }
}
