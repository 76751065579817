@use '../../../scss/abstracts/' as *;

.add-roles {
  &-modal {
    width: 634px;
  }

  &-content {
    margin-bottom: 24px;
  }

  &-buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
    }
  }
}
