@use '../../../scss/abstracts/' as *;

.upload-image {
  &__wrapper {
    .title {
      display: block;
      font-size: $font-small;
      font-weight: 700;
      margin-bottom: 12px;
    }
  }

  &__thumbnail-wrapper {
    margin-top: 28px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 28px;
  }

  &__container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 12px 18px;
    border-radius: 9px;
    position: relative;

    .button {
      font-size: $font-small;
      text-align: center;
      width: 80px;
      padding: 50px 0;

      div {
        border-radius: 9px;
        height: 165px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed #cbcbcb;
      }

      p {
        color: #757575;

        button {
          background-color: transparent;
          color: #0085ff;
          font-weight: 700;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }
    }

    .active {
      background-color: #eee;
    }

    .drag-file-element {
      position: absolute;
      width: 100%;
      height: calc(165px + 12px);
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  &__thumbnail {
    .filename {
      font-weight: 500;
      font-size: $font-small;
      margin-bottom: 0;
    }

    .icon {
      cursor: pointer;
      svg {
        display: block;
      }
    }

    &__preview-image {
      position: relative;

      .preview-image-btn {
        margin-right: 8px;
      }

      .btn {
        min-width: auto;
      }

      .preview-image-input {
        .input-text-field {
          padding-right: 100px;
        }
      }

      .thumbnail__buttons {
        position: absolute;
        display: flex;
        right: 8px;
        bottom: 5px;
      }
    }
  }
}
