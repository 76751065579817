@use '../../../../scss/abstracts/' as *;
@forward './DriverTaskList/DetailDriverTask/';
@forward './DriverTaskList/VechiclePhotos/';
@forward './WithdrawList/DetailWithdraw/detail-withdraw';

.driver {
  position: relative;

  .g-table {
    min-height: 500px;
  }

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 10;
  }

  &__title {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__input-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 23px;
    row-gap: 36px;

    .input-bank {
      .select-field-banks__dropdown {
        max-height: 120px;
      }
    }

    .input-field,
    .select-field-dropdown,
    .select-field-vehicle {
      .disable {
        background: #f5f5f5;
      }
    }
  }

  &__buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;
    }
  }

  &__balance-info {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 18px;
    border-radius: 14px;
    margin-bottom: 24px;

    p {
      font-size: 12px;
      margin-bottom: 6px;
    }

    h3 {
      font-size: 20px;
      color: #009ef7;
    }
  }

  &__transaction-history {
    h5 {
      font-weight: bold;
      font-size: 16px;
    }

    h3 {
      font-size: 20px;
      color: #009ef7;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      border-bottom: 1px solid #e0e0e0;

      &:last-of-type {
        border: none;
      }
    }

    &__empty {
      background-color: #f4f4f4;
      padding: 22px 0;
      color: #a8a8a8;
      text-align: center;
      border-radius: 4px;
      margin: 16px 0;
      font-size: 12px;
    }
  }

  .status-info {
    display: flex;
    align-items: center;
    gap: 8px;

    h3 {
      font-size: $font-small;
      line-height: normal;
    }

    input:checked + .slider {
      background-color: #299b0a;
    }
  }
}
