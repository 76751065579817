@use '../../../../scss/abstracts/' as *;

.business-partner-rental {
  position: relative;

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 10;
  }

  &__wrapper {
    background-color: $white;
    border-radius: 4px;
    font-family: $inter;
    min-width: 300px;
    padding: 18px 16px;
    min-height: 85vh;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  &__filter {
    display: flex;

    .filter-location {
      margin-right: 8px;
    }
  }

  .table-wrapper {
    width: 150%;

    thead.table-head {
      tr {
        th {
          .sorted-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 4px;
            cursor: pointer;

            div.chevrons {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  &-custom-order {
    position: relative;

    &__header {
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      margin-bottom: 21px;

      p {
        margin-left: 12px;
      }
    }

    &__publish-btn {
      position: absolute;
      top: 0;
      right: 0;
    }

    &__identity {
      div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__without-driver-form {
      display: flex;
      flex-direction: column;

      &__title {
        margin-bottom: 22px;
        text-align: left;
        width: 100%;

        h2 {
          color: #009ef7;
          font-size: 16px;
          font-weight: 700;
        }
      }

      &__form {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        row-gap: 38px;
        column-gap: 28px;

        div:nth-child(3),
        div:nth-child(4) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 14px;
        }
      }
    }

    &__with-driver-form {
      &__form {
        width: 100%;

        &__date-duration {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 14px;
          padding-bottom: 38px;
          border-bottom: 1px dashed #e0e0e0;
        }

        &__payment-passenger,
        &__baggage-passenger {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 14px;

          div {
            width: 100%;
            position: relative;
          }
        }

        &__day {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 14px;
          margin-top: 26px;

          .g-input {
            .field-wrapper {
              .input-icon-left {
                z-index: 5;
              }
            }
          }
        }
      }

      &__driver-assign {
        padding: 24px 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        column-gap: 28px;

        div {
          max-width: 100%;
          grid-column-start: 2;
          grid-column-end: 3;
        }
      }

      &__detail-sewa {
        margin: 38px 0 24px;
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        column-gap: 14px;

        h2 {
          color: #009ef7;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 26px;
        }

        &-form {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, minmax(400px, 1fr));
          row-gap: 38px;
          column-gap: 28px;
        }
      }
    }

    &__airport-transfer-form {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__form {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(400px, 1fr));
        row-gap: 38px;
        column-gap: 28px;
        margin-bottom: 50px;

        &__shuttle {
          position: relative;
          grid-column: 1/3;
          display: flex;
          justify-content: space-between;
          gap: 28px;

          div {
            width: 100%;
          }

          .switch {
            position: absolute;
            bottom: 7px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
          }
        }

        &__baggage-passenger {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          justify-content: space-between;
          gap: 14px;
        }
      }
    }

    &__table-wrapper {
      background-color: $white;
      border-radius: 4px;
      font-family: $inter;
      min-width: 300px;
      padding: 18px 16px;

      &__title {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: 16px;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    &__input-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 23px;
      row-gap: 36px;

      .input-field,
      .select-field-dropdown {
        .disable {
          background: #f5f5f5;
        }
      }
    }

    &__detail-sewa {
      &__input-group {
        border-top: 1px dashed #dbdbdb;
        margin-top: 28px;
        margin-bottom: 28px;
        padding-top: 28px;
      }
    }

    &__buttons {
      margin-top: 60px;
      text-align: right;
      .button {
        display: inline-block;
        margin-left: 7px;
        font-weight: bold;
      }
    }
  }
}
