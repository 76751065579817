@use '../../../../../../scss/abstracts/' as *;

.content--left {
  padding: 24px;

  // FIRST SECTION
  .first-section {
    width: 100%;
    padding-bottom: 33px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 36px;

    &__add-button {
      font-size: $font-small;
      position: absolute;
      top: 0;
      right: 0;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;

      .circle {
        width: 17px;
        height: 17px;
        padding: 5px;
        border-radius: 100%;
        background-color: $lightBlue;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__garage {
      padding-bottom: 33px;
      width: 100%;
    }
  }

  // SECOND SECTION
  .second-section {
    width: 100%;
    padding-top: 38px;
    padding-bottom: calc(38px - 24px);
    border-top: 1px dashed #e0e0e0;
    border-bottom: 1px dashed #e0e0e0;

    // rules
    &__rules {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px 62px;
      border-bottom: 1px dashed #e0e0e0;
      padding-bottom: 24px;

      .checkbox-input {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;

        &__box {
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 2px;
          border: 1px solid #e0e0e0;
          overflow: hidden;

          .checkmark {
            width: 100%;
            height: 100%;
            background-color: $lightBlue;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
          }

          .show {
            opacity: 1;
          }

          input[type='checkbox'] {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0;
          }
        }

        &__label {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }

    // drivers availability
    &__drivers-availability {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 18px;
      padding-top: 24px;

      .radio-input {
        display: flex;
        align-items: center;
        gap: 13px;

        &__box {
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 2px;
          border: 1px solid #e0e0e0;
          overflow: hidden;

          .checkmark {
            width: 100%;
            height: 100%;
            background-color: $lightBlue;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
          }

          .show {
            opacity: 1;
          }

          input[type='checkbox'] {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            opacity: 0;
          }
        }
      }
    }

    // prices
    &__prices {
      margin-top: 24px;

      &-driver--section {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 21px;
        margin: 37px 0 30px;
      }
    }
  }

  // THIRD SECTION
  .third-section {
    width: 100%;
    padding: 38px 0;
    display: flex;
    flex-direction: column;
    gap: 26px;

    // plat no
    &__plat {
      display: grid;
      grid-template-columns: 141px auto 128px;
      gap: 12px;
      align-items: flex-end;
    }

    // seats
    &__seats {
      display: grid;
      grid-template-columns: auto 218px;
      // grid-template-columns: auto 109px 109px;
      gap: 12px;
      align-items: flex-end;
    }

    // transmission
    &__transmission {
      width: 100%;
    }
  }
}
