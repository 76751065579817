@use '../../../scss/abstracts/' as *;

.select-field-airport-transfer-check {
  position: relative;
  width: 100%;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    width: 100%;
    max-width: 100%;
    height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    cursor: pointer;

    scrollbar-width: 40px;
    scrollbar-color: #e0e0e0;

    /* Menyelaraskan tampilan scrollbar */
    &::-webkit-scrollbar {
      height: 4px; /* Tinggi scrollbar horizontal */
      width: 40px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* Warna track scrollbar */
      margin-right: 20px;
      margin-left: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e0e0e0; /* Warna thumb scrollbar */
      border-radius: 10px; /* Radius untuk sudut bulat */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
    }

    // for Firefox
    &::-moz-scrollbar {
      width: 40px;
      height: 4px;
    }

    &::-moz-scrollbar-track {
      background: transparent; /* Warna track scrollbar */
      margin-right: 20px;
      margin-left: 20px;
    }

    &::-moz-scrollbar-thumb {
      background: #e0e0e0; /* Warna thumb scrollbar */
      border-radius: 10px; /* Radius untuk sudut bulat */
    }

    &::-moz-scrollbar-thumb:hover {
      background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
    }

    &__input {
      width: 100%;
      padding: 0 14px;
      height: 44px;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;
    }

    &__cars-wrapper {
      width: 341.5px;
      height: 44px;
      padding: 0 14px;
      pointer-events: none;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      div.chips {
        height: 28px;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        column-gap: 6px;
        align-items: center;
        border-radius: 100px;
        padding: 0 20px;
        font-size: 10px;
        margin-right: 20px;

        span {
          width: 15px;
          height: 15px;
          background-color: #009ef7;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          font-size: 12px;
        }
      }
    }
  }

  &__dropdown {
    min-width: 536.6px;
    position: absolute;
    left: 0;
    // right: 0;
    top: 110%;
    max-height: 365px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    z-index: 9999;

    ul.list-container {
      display: flex;
      flex-direction: column;
      list-style: none;

      li {
        width: 536.6px;
        min-height: 89px;
        padding: 14px 21px;
        display: flex;
        align-items: center;
        column-gap: 21px;
        border-top: 1px solid #e0e0e0;

        div.image-container {
          width: 61px;
          height: 61px;
          border-radius: 3px;
          overflow: hidden;
          background-color: #e0e0e0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .vehicle-description {
          &__title {
            font-size: $font-small;
            font-weight: bold;
            text-transform: capitalize;
            margin-bottom: 8px;
          }

          &__desc {
            display: flex;
            column-gap: 12px;
            align-items: center;

            span {
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background: #000;
            }

            p {
              font-size: 12px;
              font-weight: 400;
            }

            p:last-child {
              text-transform: capitalize;
            }
          }
        }

        .checkbox {
          margin-left: auto;
        }
      }
    }
  }
}
