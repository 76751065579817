// @use '../../../../scss/abstracts/' as *;
@use '../../../../scss/abstracts/' as *;
@forward './CreateOvertimeRules/index';
@forward './CreateOvertimePage/index';

.overtime {
  position: relative;

  .button-wrapper {
    z-index: 100;
    margin-bottom: 16px;

    .button-add {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
