@use '../../../scss/abstracts/' as *;

.btn-tabs {
  display: flex;
  align-items: center;
  font-family: $inter;

  &__item {
    margin-right: 12px;
    width: 154px;
    padding: 12px 0;
    text-align: center;
    background-color: $white;
    border-radius: 100px;
    color: #757575;
    border: 1px solid #b5b5b5;
    font-size: $font-small;

    &--active {
      background-color: $lightBlue;
      color: $white;
      font-weight: 700;
      border: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
