@use '../../../scss/abstracts/' as *;

.toast {
  position: relative;
  top: 115px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: $white;
  padding: 0 18px;
  font-size: $font-small;
  font-weight: 500;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 58px;
  // width: 100%;
  width: 468px;

  &-container {
    position: fixed;
    z-index: 10002;
    top: 0;
    // left: 50%;
    // transform: translateX(-50%);
    right: 38px;
  }

  &-success {
    color: #299b0a;
    border: 1px solid #299b0a;
  }

  &-error {
    color: #ff0000;
    border: 1px solid #ff0000;
  }

  &-warning {
    color: #ffb84d;
    border: 1px solid #ffb84d;
  }

  &-info {
    color: #0085ff;
    border: 1px solid #0085ff;
  }

  &-icon {
    margin-right: 16px;
    height: 20px;
    width: 20px;
  }

  &-content {
    display: inline-flex;
    align-items: center;
  }

  //animation class
  &-enter {
    transform: translate(0, -100%);
    opacity: 0;
  }

  &-enter-active {
    transition: all 200ms ease-out;
    opacity: 1;
    transform: translate(0, 0);
  }

  &-exit {
    transform: translate(0, -100%);
    opacity: 1;
    transition: all 200ms ease-out;
  }

  &-exit-active {
    transition: all 200ms ease-out;
    opacity: 0;
    transform: translate(0, -100%);
  }
}
