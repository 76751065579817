@use '../../../scss/abstracts/' as *;
.offcanvas {
  z-index: 999;
  left: 0;
  top: 0;
  width: 287px;
  position: sticky;
  height: 100vh;
  background-color: $darkStone;
  color: $white;
  display: grid;
  grid-template-rows: 88px auto;
  font-family: $inter;
  transition: width 0.2s;
  &-header {
    background-color: #05050a;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: width 0.2s;
    .header-logo {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      opacity: 1;
      transition: width 0.2s, opacity 0.1s 0.1s, left 0.1s;
      position: relative;
      img {
        margin-right: 7px;
        margin-bottom: 2px;
      }
      h1 {
        font-size: 1.2rem;
        span {
          color: $lightBlue;
          font-family: $poppins;
        }
      }
    }
    .arrow-icon {
      cursor: pointer;
      position: absolute;
      left: 250px;
      transition: transform 0.2s;
      &.collapse {
        transform: rotate(-180deg);
      }
    }
  }

  &-body {
    overflow-y: scroll;
    overflow-x: hidden;
    ul {
      list-style-type: none;
    }
    .list {
      .icon {
        filter: saturate(10%);
        min-width: fit-content;
      }
      p {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
      }

      &-menu {
        &.active {
          background-color: $darkVulcan;
          transition: all 0.2s;
        }
      }

      &-sub-menu {
        color: #ffffff;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        transition: all 0.2s;

        &:hover,
        &.active {
          background-color: $darkVulcan;
        }

        &:hover,
        &.active {
          .icon {
            filter: saturate(100%);
          }
        }

        .Notifikasi-icon {
          width: 18px;
          height: 18px;
        }
      }

      &-header {
        padding: 14px 0px;
      }
    }
  }
  .logout {
    &.list-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: $darkVulcan;
      }

      &:hover,
      &.active {
        .icon {
          filter: saturate(100%);
        }
      }
    }
  }

  .list-item {
    transition: all 0.2s;
    padding-top: 24px;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
    border-bottom: 2px solid $darkVulcan;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .list-menu {
    cursor: pointer;
  }
}
