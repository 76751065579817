@use '../../../../scss/abstracts/' as *;

.list-booking-zone {
  width: 100%;
  border: 0.5px solid #e0e0e0;
  background: #fff;

  .header {
    width: 100%;
    padding: 20px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #e0e0e0;
    cursor: pointer;

    &__date {
      display: flex;
      align-items: center;
      gap: 24px;

      div:first-child {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }

      div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 14px;
        font-weight: 700;

        span {
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: #000;
        }
      }

      .selected-zone {
        width: 53px;
        font-size: 10px;
        font-weight: 400;
        padding: 6.5px 10px;
        border-radius: 4px;
      }

      .selected-zone-price {
        color: #299b0a;
        font-size: 14px;
        font-weight: 600;
      }

      .zone-zero {
        color: #1c3d5d;
        background-color: #e3f1ff;
      }

      .zone-one {
        color: #dd7f00;
        background-color: #ffefd9;
      }

      .zone-two {
        color: #e5602f;
        background-color: #ffebe3;
      }
    }

    .chevron {
      transition: all 0.3s ease;
    }

    .up {
      transform: rotate(-180deg);
    }

    .down {
      transform: rotate(0);
    }
  }

  .form-booking-zone {
    overflow: hidden;
    transition: all 0.3s ease;

    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__input-data {
      display: grid;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      row-gap: 28px;
      column-gap: 32px;
      padding: 16px 24px;

      .end-time {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      &__driver-acomodation {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        column-gap: 10px;

        div.check {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        div.description {
          h5 {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 4px;
          }

          p {
            font-size: 10px;
            font-weight: 500;

            span {
              font-weight: 700;
            }
          }
        }
      }
    }

    &__time {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 14px;
    }

    &__zone-list {
      &__desc {
        list-style: none;

        li {
          display: flex;
          flex-direction: column;
          gap: 12px;
          overflow: hidden;
          position: relative;
        }

        li:first-child {
          border: none;

          h3 {
            color: #000;
            font-size: 12px;
            font-weight: 700;
          }

          .head {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              color: #000;
              font-size: 12px;
              font-weight: 400;
              span {
                font-weight: 700;
              }
            }

            div {
              width: 73px;
              height: 21px;
              border: 1px solid #1c3d5d;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #1c3d5d;
              font-size: 10px;
              font-weight: 400;
            }
          }

          .notice {
            width: 232px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #e3f1ff;
            color: #1c3d5d;
            font-size: 10px;
            font-weight: 400;
          }

          .places {
            width: 55%;
            color: #000;
            font-size: 12px;
            font-weight: 400;
            line-height: 26px;
          }
        }

        li:nth-child(2) {
          border-top: 1px dashed #e0e0e0;
          border-bottom: 1px dashed #e0e0e0;

          .head {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              color: #000;
              font-size: 12px;
              font-weight: 400;
              span {
                font-weight: 700;
              }
            }

            div {
              display: flex;
              align-items: center;
              gap: 5px;
              margin-left: 3px;
              cursor: pointer;

              span {
                color: #0085ff;
                font-size: 10px;
                font-weight: 400;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .notice {
            width: 232px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #ffefd9;
            color: #dd7f00;
            font-size: 10px;
            font-weight: 400;
          }

          .places {
            width: 55%;
            color: #000;
            font-size: 12px;
            font-weight: 400;
          }

          .x-mark {
            position: absolute;
            right: 48px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-color: #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border-radius: 100%;
            font-size: 12px;
            cursor: pointer;
          }
        }

        li:last-child {
          border: none;

          .head {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              color: #000;
              font-size: 12px;
              font-weight: 400;
              span {
                font-weight: 700;
              }
            }

            div {
              display: flex;
              align-items: center;
              gap: 5px;
              margin-left: 3px;
              cursor: pointer;

              span {
                color: #0085ff;
                font-size: 10px;
                font-weight: 400;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .notice {
            width: 280px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #ffebe3;
            color: #e5602f;
            font-size: 10px;
            font-weight: 400;
          }

          .places {
            width: 50%;
            color: #000;
            font-size: 12px;
            font-weight: 400;
            line-height: 26px;
          }

          .x-mark {
            position: absolute;
            right: 48px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-color: #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border-radius: 100%;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }

    &__stay-question {
      width: 100%;
      background: #e7f3ff;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1c3d5d;
      font-size: 12px;
      font-weight: 500;
      padding: 14px;
      border-top: 1px dashed #e0e0e0;
    }

    &__total-price-zone {
      padding: 10px 14px;
      font-size: 12px;
      font-style: normal;
      color: #000;
      font-weight: 400;

      span {
        color: #299b0a;
        font-weight: 700;
      }
    }

    &__action-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 18px;
    }
  }
}

.list-booking-zone:first-child {
  border-radius: 5px 5px 0 0;
}

.list-booking-zone:last-child {
  border-radius: 0 0 5px 5px;
}
