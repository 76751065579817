@use '../../../../scss/abstracts/' as *;

.notification {
  .g-table {
    padding: 0;
    min-height: 500px;
    margin-bottom: 16px;

    .table-header {
      padding: 18px 16px;
      margin-bottom: 0;
      border-bottom: 1px solid #dbdbdb;
    }
  }

  &-detail__item,
  &__item {
    padding: 16px;
    font-size: $font-small;
    position: relative;
    border-bottom: 1px solid #e0e0e0;

    &:last-of-type {
      border: none;
      border-radius: 0 0 4px 4px;
    }

    // &:first-of-type {
    //   border-top: 1px solid #e0e0e0;
    // }

    &--header {
      display: flex;
      align-items: flex-start;

      h1 {
        font-size: $font-medium;
      }
    }

    &--icon {
      margin-right: 12px;
    }

    &--body {
      &-title {
        margin-bottom: 6px;
      }
      &-message {
        max-width: 755px;

        .withdraw-notification {
          display: flex;
          flex-direction: column;
          row-gap: 12px;

          div {
            display: flex;
            align-items: center;
            column-gap: 10px;

            span {
              width: 4px;
              height: 4px;
              background: #000;
              border-radius: 100%;
            }
          }

          p.footer-note {
            margin-top: 20px;
          }
        }
      }
      &-created-at {
        margin-bottom: 16px;
        color: #8c8c8c;
      }
    }

    &--unread {
      background-color: #e9f4ff;
    }
  }

  &__item {
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
    }

    &--unread:hover {
      background-color: #d4e7fa;
    }
  }

  &-detail {
    .g-table {
      min-height: auto;
    }
    &__back-btn {
      display: flex;
      width: fit-content;
      margin-bottom: 21px;

      &:hover {
        cursor: pointer;
      }

      p {
        margin-left: 12px;
        font-size: $font-medium;
      }
    }

    &__item {
      &--header {
        margin-bottom: 32px;
      }

      &--button {
        width: fit-content;
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}
