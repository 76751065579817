@use '../../scss/abstracts/' as *; // kalau ingin menggunakan style utility, import folder scss pake use kaya di samping

.layout {
  min-height: 100vh;
  display: flex;
  background: $backgroundColor;

  &-sidebar {
    min-height: 100vh;
  }

  &-content {
    position: relative;
    width: 100%;

    .content {
      padding: 24px;
    }
  }

  .about-us,
  .delivery,
  .garage,
  .zone,
  .promo,
  .banner,
  .car-brands,
  .roles,
  .category,
  .roles,
  .transaction-without-driver,
  .transaction-airport-transfer,
  .down-payment,
  .voucher,
  .notif,
  .city,
  .user,
  .min-rent,
  .driver,
  .business {
    .button-wrapper {
      margin-bottom: 16px;
      .button-add {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .button-label {
      margin-left: 8px;
    }
  }
}
