@use '../../../scss/abstracts/' as *;

.confirmation {
  &-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    width: 403px;
    height: 187px;
    padding: 20px;
    border-radius: 12px;
    z-index: $modal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 27px;
  }

  &-desc {
    width: 80%;
    text-align: center;
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    gap: 18px;

    .button {
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
}
