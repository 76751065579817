@use '../../../../scss/abstracts/' as *;

.user {
  width: 100%;
  margin-inline: auto;
  padding-bottom: 3rem;
  position: relative;

  &-influencer {
    &__history {
      .g-table {
        position: relative;
        min-height: 500px;

        .order-transaction-key {
          color: #0084ff;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__button-add {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__identity {
    margin-bottom: 16px;
  }

  &__add-user-influencer {
    .g-table {
      margin: 16px 0;
    }

    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      margin-bottom: 60px;
      align-items: flex-end;

      .referral-code-field {
        position: relative;

        .random-code-btn {
          position: absolute;
          bottom: 5px;
          right: 12px;

          &:disabled {
            background-color: #e0e0e0;
          }
        }
      }

      .comission-field,
      .status-info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
          color: #000;
          font-size: 12px;
          font-weight: 700;
        }

        .radio {
          display: flex;
          gap: 24px;
          align-items: center;
        }
      }

      .status-info {
        &__toggle-wrapper {
          display: flex;
        }
      }

      .double-column {
        display: flex;
        column-gap: 18px;

        .g-input {
          width: 100%;
        }
      }
    }

    .buttons {
      justify-content: flex-end;
      display: flex;
      column-gap: 10px;
    }
  }

  .g-table {
    position: relative;
    min-height: 500px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;

    span {
      margin-left: 0.75rem;
    }
  }

  &__level-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    background-color: white;
    border-radius: 4px;
    padding: 1rem;
  }

  &__status-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    // max-width: calc(50% - 6px);
    background-color: white;
    border-radius: 4px;
    padding: 1rem;
  }

  &__status-info {
    display: flex;
    align-items: center;

    p {
      font-size: $font-small;
      font-weight: 700;
    }
  }

  &__status-badge {
    display: inline-flex;
    align-items: center;
    font-size: $font-small;
    margin: 0 1rem;
    font-weight: 600;
    padding: 5px 39px;
    border-radius: 5px;

    &--active {
      color: #007a05;
      background-color: #c5ffc7;
    }

    &--blocked {
      color: #ba0000;
      background-color: #ffc5c5;
    }

    &--premium {
      background-color: #ffc727;
      border-radius: 100px;
      padding: 5px 29px;
      font-weight: 700;
    }
  }

  &__request-level {
    color: #0085ff;
    font-weight: 400;
    font-size: $font-small;
    cursor: pointer;
  }

  &__data,
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  &__data {
    margin-bottom: 16px;
  }

  &__form-data {
    background-color: white;
    padding: 1rem;
    padding-bottom: 2.5rem;
    border-radius: 4px;

    & > * ~ * {
      margin-top: 18px;
    }
  }

  &__history-data {
    background-color: white;
    padding: 1rem;
    padding-bottom: 2rem;
    margin-top: 12px;

    &-title {
      display: flex;
      align-items: center;
      font-size: $font-medium;
      margin-bottom: 22px;
    }
  }

  &__payment-info {
    display: flex;
    margin-top: 27px;

    & > * ~ * {
      margin-left: 44px;
    }

    &-heading {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: $font-small;
    }

    &-text {
      margin-top: 0.75rem;
      font-size: $font-medium;

      &-amount {
        font-weight: 700;
        color: #009ef7;
      }
    }
  }

  &__image-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    background-color: white;
    height: 100%;
    border-radius: 4px;

    &-title {
      display: flex;
      align-items: center;
      font-size: $font-medium;
      margin-bottom: 27px;
    }
  }

  &__identity {
    &-subtitle {
      font-weight: 700;
      margin-bottom: 14px;
    }

    &-image-wrapper {
      img {
        max-height: 300px;
        width: 100%;
      }
    }
  }

  &__save-btn {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }

  // toggle switch
  &__toggle-status {
    display: flex;
    align-items: center;

    .switch {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 16px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 16px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 1.5px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }
}
