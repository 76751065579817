@use '../../../../scss/abstracts/' as *;

.driver-task {
  &__wrapper {
    background-color: $white;
    border-radius: 4px;
    font-family: $inter;
    min-width: 300px;
    padding: 18px 16px;
    min-height: 85vh;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__current-month-wrapper {
      width: 180px;
    }

    &__current-month {
      font-size: 20px;
      margin-right: 18px;
    }

    &__btn-group {
      display: flex;

      & > * + * {
        margin-left: 4px;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      transition: 0.2s all;
      padding: 10px 12px;
      cursor: pointer;
      border: none;
      background-color: $lightBlue;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__body {
    display: flex;
    flex: 1 1 0%;
  }

  &__month-wrapper {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    background-color: #f7f7f7;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    p {
      font-size: 14px;
      font-weight: 600;
      padding: 12px 0;
      text-align: center;
    }
  }

  &__month {
    flex: 1 1 0%;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  &__day-wrapper {
    border: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
  }

  &__day-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__day-item {
    font-size: 14px;
    text-align: center;
    padding: 2px;
    margin: 2px;
    font-weight: 500;
  }

  &__day-item--active {
    color: #0084ff;
    width: 28px;
    font-weight: 800;
    border-radius: 9999px;
  }

  &__task-item {
    font-size: 12px;
    color: #fff;
    background-color: #0084ff;
    border-radius: 5px;
    padding: 4px;
    margin: 0 6px;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  &__show-more-btn {
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.2s all;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #0084ff;
    font-size: 12px;
    align-self: self-start;
    margin-top: 2px;
    padding: 0 4px;
    margin: 0 8px;

    &:hover {
      opacity: 0.75;
    }
  }

  &__modal-wrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal-content {
    background-color: #fff;
    border-radius: 8px;
    width: 330px;
    box-shadow: 0 4px 6px 4px rgb(0 0 0 / 0.1), 0 2px 4px -1px rgb(0 0 0 / 0.1);
    padding: 14px;
  }

  &__modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &__close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      transition: 0.2s all;
      // padding: 2px;
      cursor: pointer;
      border: none;
      background-color: transparent;

      &:hover {
        background-color: #dfdfdf;
      }

      span {
        padding: 2px 6px;
      }
    }
  }

  &__modal-body {
    h4,
    p {
      font-size: 12px;
    }

    & > * + * {
      margin-top: 12px;
    }
  }

  &__items {
    &:not(:last-child) {
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 12px;
    }

    & > * + * {
      margin-top: 5px;
    }
  }

  &__item-group {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 6px;
    }
  }

  &__bottom-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__detail-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      transition: 0.2s all;
      cursor: pointer;
      border: none;
      background-color: transparent;
      color: #0084ff;
      font-size: 10px;

      &:hover {
        opacity: 0.75;
      }

      span {
        display: block;
        margin-left: 8px;
      }
    }
  }

  &__sidebar {
    width: 233px;
    margin-left: 18px;
    height: 100%;
  }

  &__search-wrapper {
    position: relative;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 1px;

    .search-input {
      width: auto;
      height: 29px;
      // border-radius: 3px;
      background: $white;
      border: none;
      font-size: $font-small;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-weight: 500;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__sidebar-task-list-wrapper {
    position: sticky;
    // display: flex;
    // flex-direction: column;
    flex: 1 1 0%;
    width: 233px;
    height: 87vh;
    margin-top: 8px;
    padding: 12px;
    overflow-y: auto;
    border: 1px solid #dcdcdc;
    border-radius: 5px;

    & > * + * {
      margin-top: 14px;
    }
  }

  &__sidebar-task-list:not(:first-child) {
    border-top: 1px solid #dbdbdb;
    padding-top: 14px;
  }

  &__sidebar-task-date {
    margin-bottom: 12px;
  }

  &__sidebar-item {
    margin-bottom: 12px;
    & > * + * {
      margin-top: 5px;
      // margin-bottom: 5px;
    }

    p {
      font-size: 12px;
    }
  }

  &__sidebar-item-group {
    display: flex;
    align-items: center;

    h4,
    p {
      font-size: 12px;
    }

    & > * + * {
      margin-left: 6px;
    }
  }

  &__sidebar-detail-btn {
    margin-top: 10px;
  }
}
