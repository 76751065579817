@use '../../../../../../scss/abstracts/' as *;

.with-driver-form {
  &__form {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 38px;

    &__rental-location {
      margin-bottom: 24px;
    }

    &__deposit-refferal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      div {
        width: 100%;
        position: relative;
      }
    }

    &__date-duration {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    &__time {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 14px;
    }

    &__payment-passenger,
    &__baggage-passenger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      div {
        width: 100%;
        position: relative;
      }
    }

    &__day {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 14px;
      margin-top: 26px;

      .g-input {
        .field-wrapper {
          .input-icon-left {
            z-index: 5;
          }
        }
      }
    }
  }

  &__booking-zone {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin: 38px 0 27px;
    padding-bottom: 27px;
    border-bottom: 1px dashed #e0e0e0;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__driver-assign {
    padding: 24px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;

    div {
      max-width: 100%;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  &__button-action {
    margin: 0 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;

    &__action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  &__detail-sewa {
    margin: 38px 0 24px;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 26px;
    }

    &-form {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      row-gap: 38px;
      column-gap: 28px;
    }
  }

  &__additional-item {
    width: 100%;
    margin-bottom: 36px;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    div.wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 38px;
      column-gap: 28px;

      .additional {
        grid-column: 1 / 3;
      }

      // .input-text-area-title {
      //   margin-bottom: 4px;
      // }
    }
  }
}
