@use '../../../../../../scss/abstracts/' as *;

.harga-potongan-detail {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 24px;

  h1 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }

  .actions-button {
    margin-top: 46px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}
