@use '../../../scss/abstracts/' as *;
@forward './ServicesForm/index';

.city-modal {
  width: 929px;

  &-form {
    & > * + * {
      margin-top: 27px;
    }

    &__form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 18px;
      row-gap: 29px;

      &__services {
        display: flex;
        align-items: center;
        column-gap: 14px;
      }
    }

    &__services {
      width: 100%;

      .tabs-container {
        .tab-list {
          border-bottom: 1px solid #e0e0e0;
          display: flex;
          justify-content: space-evenly;
          padding: 0;

          &-item {
            border-bottom: 1px solid #e0e0e0;
            border-radius: 12px 12px 0 0;
            padding: 14px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
