@use '../../../scss/abstracts/' as *;

.bank-transfer {
  width: 960px;
  min-height: 80vh;
  display: grid;
  grid-template-columns: 30% auto;

  &--left {
    padding-right: 28px;
    border-right: 1px solid #e0e0e0;
  }

  &--right {
    padding-left: 28px;
    display: flex;
    flex-direction: column;
  }

  &__data {
    min-height: 60px;
    padding: 12px;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &__image {
    margin-bottom: 40px;

    .image-container {
      position: relative;
      border-radius: 5px;
      max-height: 550px;
      background-color: #a3a3a3;
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        width: inherit;
        height: inherit;
      }

      svg {
        position: absolute;
        bottom: 12px;
        right: 12px;
        cursor: pointer;
      }
    }
  }

  &__title,
  &__value {
    word-wrap: break-word;
    font-size: $font-small;
    text-transform: uppercase;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__buttons {
    text-align: right;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;
    }
  }
}
