@use '../../../scss/abstracts/' as *;

.add-ons-field {
  margin-bottom: 42px;

  &-title {
    margin-bottom: 18px;

    h1 {
      color: $lightBlue;
      font-size: $font-label;
    }
  }

  &-body {
    background-color: #f7f7f7;
    position: relative;
    width: 100%;
    border-radius: 4px;
    border: none;
    outline: 1px solid #e0e0e0;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px 12px;
    gap: 8px;

    &-item {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 4px 6px;
      border-radius: 5px;
      box-shadow: 0px 0px 2px 0px #00000040;

      &-image {
        position: relative;
        width: 30px;
        height: 30px;
      }

      &-info {
        p,
        h6 {
          font-size: 10px;
        }

        h6 {
          font-weight: 700;
        }

        &-variety {
          display: flex;
          align-items: center;
          gap: 20px;

          .font-bold {
            font-weight: 700;
          }
        }
      }

      &-price {
        font-weight: 700;
        color: #1c3d5d;
      }
    }

    &-empty {
      background-color: #f4f4f4;
      padding: 22px 0;

      p {
        font-size: $font-small;
        color: #a8a8a8;
        width: 100%;
        text-align: center;
      }
    }
  }

  &-image {
    position: absolute;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
    height: 100%;
    width: 100%;
  }

  .g-input {
    width: 50%;
    margin-top: 24px;
  }
}
