@use '../../../scss/abstracts/' as *;

.deposit-modal {
  max-width: 1291px;
  max-height: 616px;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 289px);
    gap: 0 10px;
    max-height: 420px;
    margin-bottom: 78px;
  }

  &__column {
    &:not(:first-child) {
      border-left: 1px solid #e0e0e0;
      padding-left: 12px;
    }

    &:last-child {
      position: relative;
    }
  }

  &__info {
    height: 60px;
    padding: 12px;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &__title,
  &__value {
    font-size: $font-small;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__identity {
    margin-top: 0.875rem;

    & > * ~ * {
      margin-top: 11px;
    }

    &--image {
      width: 100%;
    }
  }

  &__form-check {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
  }

  &__check-input {
    width: 15px;
    height: 15px;
  }

  &__check-label {
    margin-left: 8px;
    font-size: $font-small;
  }

  &__buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }
}
