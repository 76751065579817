@use '../../../../../../scss/abstracts/' as *;

.detail-promo-list {
  margin-top: 40px;
  position: relative;

  .checkbox-new-user {
    position: absolute;
    top: -64px;
    right: 0;
  }

  h2 {
    font-size: $font-medium;
    margin-bottom: 18px;
  }

  &__type-promo {
    position: relative;

    span.checkbox-field {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  form {
    margin-top: 32px;

    .form-field {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(300px, 1fr));
      column-gap: 23px;
      row-gap: 36px;

      &__name-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 14px;

        div {
          width: 100%;
        }
      }

      &__period {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
          color: #000;
          font-size: 12px;
          font-weight: 700;
        }

        &__date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 14px;

          div {
            div {
              div:last-child {
                top: 40px;
              }
            }
          }
        }
      }

      &__price {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
          color: #000;
          font-size: 12px;
          font-weight: 700;
        }

        &__radio {
          display: flex;
          gap: 24px;
          align-items: center;
        }
      }

      &__package {
        align-self: flex-end;
      }

      &__text-area-input {
        textarea {
          height: 161px;
        }
      }

      &__promo-image {
        display: flex;
        flex-direction: column;
        gap: 28px;

        h3 {
          color: #000;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }

    .action-button {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
