@use '../../../../scss/abstracts/' as *;

.banner {
  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .button-label {
    margin-left: 8px;
  }

  &__header {
    padding: 0 24px;
    margin-bottom: 38px;

    h3 {
      font-size: $font-small;

      &:first-of-type {
        margin-bottom: 14px;
      }
    }
  }

  // &__body {
  //   display: flex;
  //   flex-wrap: wrap;
  //   padding: 0 24px;
  //   gap: 80px;
  // }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    padding: 0 24px;
    margin: 0 30px;
    row-gap: 36px;
  }

  &__item {
    padding: 14px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    max-width: 415px;
    justify-self: center;
  }

  &__image-wrapper {
    width: 387px;
    height: 115px;
    margin-bottom: 18px;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
    }
  }

  &__icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    img:hover {
      cursor: pointer;
    }
  }

  // &__content {
  //   background-color: $white;
  //   border-radius: 4px;
  //   font-family: $inter;
  //   min-width: 300px;
  //   padding: 18px 16px;
  //   margin-top: 1rem;
  // }

  // &__heading {
  //   display: flex;
  //   align-items: center;
  // }

  // &__title {
  //   margin-left: 10px;
  // }
}
