@forward './TourDetail/tour-detail';

.tour {
  .button-wrapper {
    margin-bottom: 16px;

    .button {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
