.zone {
  &-detail {
    &__header {
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      margin-bottom: 21px;

      p {
        margin-left: 12px;
      }
    }

    &__content {
      margin-bottom: 28px;

      &-title {
        border-bottom: 1px solid #dbdbdb;
        padding-bottom: 24px;
        margin-bottom: 20px;
      }

      &-add-zone {
        margin-left: auto;
        margin-right: 0;
        width: fit-content;
        margin-bottom: 18px;

        .button-add {
          .button-label {
            margin-left: 5px;
          }
        }
      }

      &-list {
        display: grid;
        grid-template-rows: 1fr;
        row-gap: 18px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}
