@use '../../../../scss/abstracts/' as *;

.city {
  .add-btn {
    margin-left: auto;
    margin-bottom: 16px;

    span {
      margin-left: 8px;
    }
  }

  table {
    th:nth-child(1) {
      min-width: 45px;
    }

    th:nth-child(3),
    th:nth-child(4) {
      min-width: 100px;
    }
  }
}
