@use '../../../../scss/abstracts/' as *;

.dashboard {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 104px 140px 140px 140px 1fr 1fr 1fr;
  grid-template-areas: 'item1 item2 item3' 'item4 item5 item5' 'item4 item6 item6' 'item7 item7 item7' 'item7 item7 item7' 'item8 item8 item8' 'item9 item9 item9' 'item10 item10 item10';
  column-gap: 16px;
  row-gap: 24px;

  div:first-child {
    grid-area: item1;
  }

  div:nth-child(2) {
    grid-area: item2;
  }

  div:nth-child(3) {
    grid-area: item3;
  }
  div:nth-child(4) {
    grid-area: item4;
  }

  div:nth-child(5) {
    grid-area: item5;
  }

  div:nth-child(6) {
    grid-area: item6;
  }

  div:nth-child(7) {
    grid-area: item7;
  }

  div:nth-child(8).dashboard-order {
    grid-area: item8;
  }

  div:nth-child(9).dashboard-order {
    grid-area: item9;
  }

  // div:nth-child(10).dashboard-order {
  //   grid-area: item10;
  // }

  div:last-child.dashboard-order {
    grid-area: item10;
  }

  &-transactions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    div:first-child.card-dashboard-item {
      width: 49%;
    }

    div:last-child.card-dashboard-item {
      width: 49%;
    }
  }

  &-order {
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    .header-logo {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 10px;

      h2 {
        font-size: $font-medium;
      }

      .logo {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $lightBlue;
        border-radius: 100%;
      }

      .logo-skeleton {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background: #eae9e9;
      }

      h2.title-skeleton {
        width: 120px;
        height: 18px;
        background: #eae9e9;
      }
    }

    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      gap: 24px;

      div.card-dashboard-item {
        height: 104px;
      }
    }
  }
}
