@use '../../../../scss/abstracts/' as *;

.min-rent {
  &__title {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__wrapper {
    padding-bottom: 250px;
  }

  &__input-group {
    .input-field,
    .select-field-dropdown,
    .select-field-vehicle {
      margin-bottom: 24px;

      .disable {
        background: #f5f5f5;
      }
    }
  }

  &__car-input {
    border-top: 2px;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-color: #bbbbbb;
    border-style: dashed;
    padding-top: 24px;

    .select-field-vehicle__dropdown {
      margin-top: 30px;
    }
  }

  &__periode {
    h3 {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }

  &__periode-input-date {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;

    div {
      div {
        div:last-child {
          top: 40px;
        }
      }
    }
  }

  &__buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;
    }
  }
}
