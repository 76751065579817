@use '../../../scss/abstracts/' as *;

.add-violations {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  width: 360px;
  // height: 187px;
  padding: 28px 22px;
  border-radius: 10px;
  z-index: $modal;
  display: flex;
  flex-direction: column;
  gap: 26px;

  &__title {
    color: #000;
    font-size: $font-large;
    font-weight: 700;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
