@use '../../../../../scss/abstracts/' as *;

.detail-penyewa {
  margin-top: 32px;

  &__detail-penyewa-form {
    &__title {
      color: $lightBlue;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    &__rental-location {
      margin-bottom: 34px;
    }

    &__form {
      width: 100%;

      &__one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 35px;

        div {
          width: 48.5%;
        }

        div:nth-child(3) {
          margin-top: 32px;
        }
      }

      &__two {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          width: 48%;

          div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            h3 {
              color: black;
              font-size: $font-small;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  &__detail-deposit-form {
    margin-top: 36px;

    &__title {
      color: black;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    &__form {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 48%;
      }
    }
  }

  &__button-action {
    margin: 90px 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}
