@use '../../../scss/abstracts/' as *;

.deposit-confirmation {
  width: 395px;
  max-height: 687px;
  background: $white;
  overflow-y: auto;
  padding: 0 0.5rem;

  & > * + * {
    margin-top: 25px;
  }

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .bank-input-modal {
    position: relative;

    &-wrapper {
      position: absolute;
      top: 2px;
      z-index: 40;
      width: 100%;
      max-height: 224px;
      overflow: auto;
      border-radius: 8px;
      background-color: $white;
      padding: 12px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    &-loading,
    &-unavailable {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: center;
      font-size: $font-medium;
    }

    &-item {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d9d9d9;
      padding: 14px 2px;
      font-size: $font-small;
      font-weight: bold;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity,
        box-shadow, transform, filter, backdrop-filter;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      &:hover {
        background-color: #f1faff;
      }
    }
  }
}
