@use '../../../scss/abstracts/' as *;

.reward-input {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .input-text {
    &-title {
      display: block;
      font-size: $font-small;
      font-weight: 700;
      // margin-bottom: 12px;
    }

    &-field {
      position: relative;
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border: none;
      outline: 1px solid #e0e0e0;
      font-size: $font-small;
      display: flex;
      align-items: center;
      padding: 0 12px;
      font-weight: 500;
      background-color: #f5f5f5;
      pointer-events: none;

      &::placeholder {
        font-size: 12px;
      }

      &:focus {
        outline-color: #0085ff;
      }

      &.with-icon {
        padding-right: 38px;
      }

      &.with-currency {
        padding-left: 68px;
      }
    }
  }

  .field-wrapper {
    position: relative;

    .see-password {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
      text-transform: capitalize;

      option {
        height: 40px;
      }
    }

    &.with-icon {
      display: flex;
      width: 100%;
    }

    .input-icon {
      width: 18px;
      height: auto;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);

      &.password {
        cursor: pointer;
      }
    }

    .input-price {
      font-size: $font-small;
      padding-right: 14px;
      height: auto;
      border-right: 1px solid #e0e0e0;
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .field-reward-list {
    width: 100%;

    &__list {
      width: 100%;
      display: grid;
      list-style: none;
      grid-template-columns: repeat(5, minmax(100px, 1fr));
      column-gap: 2px;

      li:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      li:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      li {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #b8ddff;
        color: #0085ff;
        padding: 10px;
        cursor: pointer;
      }

      .active {
        background-color: #0085ff;
        color: #fff;
      }
    }
  }
}
