@use '../../../../../scss/abstracts/' as *;

.tour-detail {
  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 22px;

    .content {
      &--left,
      &--right {
        background-color: $white;
        border-radius: 4px;
      }

      &--left {
        padding: 24px;

        .g-input {
          margin-bottom: 24px;
        }

        &__layanan {
          margin-bottom: 32px;
          h3 {
            font-size: $font-small;
            margin-bottom: 14px;
          }

          .input-group {
            display: flex;
            gap: 20px;
          }
        }

        .textarea-input {
          textarea {
            height: 181px;
            width: 100%;
          }
        }
      }

      &--left .textarea-input,
      &--right .textarea-input {
        margin-bottom: 18px;

        h3 {
          font-size: $font-small;
          margin-bottom: 14px;
        }

        textarea {
          resize: none;
          border-radius: 4px;
          border: none;
          outline: 1px solid #e0e0e0;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          padding: 9px 12px;
          font-weight: 500;
        }
      }

      &--right {
        p {
          margin-bottom: 12px;
          font-size: $font-small;
        }
      }
    }
  }

  &__button {
    .button {
      margin-left: auto;
      margin-right: 0;
      font-size: $font-small;
    }
  }
}
