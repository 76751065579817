.development-dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);

  &__panel {
    width: 380px;
    height: 380px;
    padding: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  &__image {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  &__description {
    text-align: center;
    font-size: 16px;
  }

  // &__button {
  //   width: 100%;
  //   margin-top: 28px;
  //   border: none;
  //   padding-top: 12px;
  //   padding-bottom: 12px;
  //   line-height: 15px;
  //   background-color: $lightBlue;
  //   border-radius: 6px;
  //   font-size: $font-small;
  //   font-weight: bold;
  //   color: #ffffff;
  // }
}
