@use '../../../../../scss/abstracts/' as *;

.rented-cars {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 100px;
  }

  &__lists {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 12px;

      div:first-child {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $font-medium;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
      }

      span {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
