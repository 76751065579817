@use '../../../scss/abstracts/' as *;

.g-input {
  .input {
    &-title {
      display: block;
      font-size: $font-small;
      font-weight: 700;
    }

    &-field {
      width: 100%;
      height: 44px;
      border-radius: 4px;
      border: none;
      outline: 1px solid #e0e0e0;
      font-size: $font-small;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-weight: 500;

      &:focus {
        outline-color: #0085ff;
      }
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  .field-wrapper {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-transform: capitalize;

      option {
        height: 44px;
      }
    }
  }
}
