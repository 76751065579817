@use '../../../scss/abstracts/' as *;

.block-confirmation {
  @include absolute();
  @include flex(center, center, column);
  z-index: $modal;
  background: $white;
  width: 403px;
  border-radius: 12px;
  padding: 54px 26px 37px;

  &__btn-group {
    display: flex;
    width: 100%;
    margin-top: 33px;

    & > * + * {
      margin-left: 18px;
    }
  }
}
