.chat {
  width: 100%;
  height: 80vh;
  display: flex;
  background-color: white;
  border-radius: 4px;

  &__sidebar {
    width: 350px;
    border-right: 1px solid #ccc;
  }

  &__sidebar-wrapper {
    padding: 0 24px;
    padding-bottom: 24px;
  }

  &__sidebar-chat-list {
    height: 100%;

    & > * + * {
      margin-top: 20px;
    }
  }

  &__sidebar-chat-item {
    display: flex;
    gap: 16px;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;

    &__selected {
      background-color: rgba(0, 158, 247, 0.06);
    }

    &__unread {
      background-color: rgba(247, 247, 247, 1);
    }
  }

  &__sidebar-user-icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #0084ff;
  }

  &__sidebar-chat-info {
    flex: 1 1 0%;
  }

  &__sidebar-info-item {
    display: flex;
    justify-content: space-between;

    &__chat-id {
      display: block;
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }

    &__chat-time {
      color: rgba(0, 0, 0, 0.4);
      font-size: 12px;
    }
  }

  &__sidebar-chat-history {
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.75rem;
  }

  &__sidebar-chat-image {
    display: flex;
    align-items: center;

    p {
      margin-left: 4px;
    }
  }

  &__sidebar-chat-badge {
    margin-top: 8px;
    display: inline-block;
    padding: 0.25em 0.75em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    border-radius: 0.5rem;

    &__open {
      color: #299b0a;
      background-color: #dbffde;
    }

    &__close {
      color: rgba(255, 0, 0, 1);
      background-color: rgba(255, 228, 228, 1);
    }
  }

  &__right-side {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 24px;

    &__info {
      display: flex;
      gap: 1rem;
    }

    &__user-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background-color: #0084ff;
    }

    &__chat-id {
      font-size: 20px;
    }

    &__user-status {
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 8px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);

      &__badge {
        display: inline-block;
        border-radius: 999px;
        width: 10px;
        height: 10px;
      }

      .online {
        background-color: rgba(104, 211, 145, 1);
      }

      .closed {
        background-color: rgba(216, 216, 216, 1);
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      transition: 0.2s all;
      min-width: 100px;
      padding: 10px 16px;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      border: none;

      &:hover {
        opacity: 0.75;
      }

      &.online {
        background-color: rgba(255, 228, 228, 1);
        color: rgba(255, 0, 0, 1);
      }

      &.closed {
        background-color: rgba(41, 155, 10, 1);
        color: #fff;
      }
    }
  }

  &__message {
    position: relative;
    padding: 24px;
    flex: 1 1 0%;
    display: flex;
    overflow-y: auto;
    flex-direction: column-reverse;
    overscroll-behavior-y: contain;

    & > * + * {
      margin-bottom: 10px;
    }
  }

  &__message-item {
    display: flex;

    &__other {
      justify-content: flex-end;
    }

    &__user {
      justify-content: flex-start;
    }
  }

  &__message-content {
    border-radius: 12px;
    padding: 8px;
    max-width: 70%;

    &__other {
      background-color: #0084ff;
      color: white;
    }

    &__user {
      background-color: rgba(241, 241, 241, 1);
    }

    &__image {
      width: 300px;
      height: 300px;
      cursor: pointer;

      // overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  &__message-form {
    flex: 1 1 0%;
    gap: 24px;
  }

  &__message-input {
    display: flex;
    padding: 24px;
  }

  &__message-input-form {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    // max-width: 400px;
    margin: 12px 0;

    &__field {
      width: 100%;
      padding: 8px;
      border: none;
      outline: none;
      font-size: 12px;
    }
  }

  &__message-input-btn {
    position: absolute;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.2s all;
    // min-width: 100px;
    // padding: 8px 16px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  &__message-upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: 0.2s all;
    margin-right: 10px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  &__file-upload {
    position: absolute;
    bottom: -20px;
    right: -50px;
    // left: 50%;
    transform: translate(-50%);
    // max-width: 128px;
    border-radius: 12px;
    padding: 8px 10px;
    background-color: #e2e8f0;

    &__img-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;

      img {
        object-fit: cover;
      }

      .close-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 999px;
        transition: 0.2s all;
        top: -10px;
        right: -35px;
        font-weight: 700;
        cursor: pointer;
        border: none;
        // background: transparent;
      }
    }
  }

  &__input-file {
    display: none;
  }
}

/* SEARCH INPUT */
.search-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin: 12px 0;

  &__field {
    flex: 1;
    padding: 8px;
    border: none;
    outline: none;
    font-size: 12px;
  }
}

/* TABS */
.tabs {
  display: flex;
  border-bottom: 1px solid #c6c6c6;
  padding: 1.5rem;
  padding-bottom: 14px;

  & > * + * {
    margin-left: 8px;
  }
}

.tabs-content {
  height: 45vh;
  overflow-y: auto;
  padding-right: 20px;
}

.tab-button {
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  background: #f1f1f1;
  width: 100%;
  color: #757575;
  cursor: pointer;

  &__active {
    background-color: #0084ff;
    color: white;
  }

  &:hover {
    opacity: 0.75;
  }
}

.tab-button-type {
  padding: 8px 16px;
  border-radius: 0;
  border: none;
  background: transparent;
  border-bottom: 2px solid transparent;
  margin-bottom: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__active {
    border-bottom: 2px solid rgba(0, 132, 255, 1);
    // color: #1d3557;
    font-weight: 700;
  }

  &:hover {
    border-bottom: 2px solid rgba(0, 132, 255, 1);
  }
}

.tabs-badge {
  width: 28px;
  height: 19px;
  // display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(241, 163, 58, 1);
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 24px;
  color: white;
  font-weight: 600;
  margin-left: 4px;
  pointer-events: none;
}

.tab-page {
  height: 100%;
  display: none;

  &__active {
    display: block;
  }
}

.empty-chat {
  // width: 640px;
  height: 100%;
  margin: 0 auto;
  padding: 10px;
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 22px;
    }
  }
}
