@use '../../../scss/abstracts/' as *;

.select-field-dropdown {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    padding: 0 14px;
    border-radius: 5px;
    background-color: white;
    outline: 1px solid #e0e0e0;
    column-gap: 12px;
    min-height: 44px;

    &__input {
      width: 97%;
      border: none;
      outline: none;
      background: transparent;
      min-height: 44px;
      font-size: $font-small;
      cursor: default;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__input:focus & {
      outline-color: #0085ff;
    }

    &__list {
      width: 97%;
      border: none;
      outline: none;
      background: transparent;
      min-height: 44px;
      font-size: $font-small;
      cursor: default;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
      padding: 8px 0;

      span {
        height: 28px;
        background-color: #f2f2f2;
        display: flex;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        padding: 0 20px;
      }
    }

    &__arrow {
      transform: rotate(0deg);
      transition: all ease 0.3s;
    }

    .down {
      transform: rotate(-180deg);
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  .driver {
    background: #e5f3ff;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 176px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 999;

    li:nth-child(1) {
      border-top: none;
    }

    li {
      height: 44px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 11px 14px;
      gap: 14px;
      border-top: 1px solid #e0e0e0;
      background-color: white;

      div {
        width: 38px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        color: black;
        pointer-events: none;
      }
    }

    li.list:hover {
      filter: brightness(95%);
    }

    li.list {
      cursor: pointer;
    }

    li.list-check {
      position: relative;

      div {
        width: 18px;
        height: 18px;
        background: #fff;
        position: relative;

        input[type='checkbox'] {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          cursor: pointer;
          opacity: 0;
        }
      }

      .check {
        background: #0085ff;
      }

      label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
