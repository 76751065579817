@use '../../../../../../scss/abstracts/' as *;

.tac-location-detail {
  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__location-wrapper {
    width: 100%;
    margin: 24px 0 56px;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 29px;
  }

  div {
    div {
      .tab-list-item {
        background-color: #e0e0e0;
      }

      .tab-list-active {
        background-color: $lightBlue;
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
  }
}
