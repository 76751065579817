@use '../../../scss/abstracts/' as *;

.input-violation {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    &-title {
      display: block;
      font-size: $font-small;
      font-weight: 700;
    }

    &-add {
      display: flex;
      cursor: pointer;

      p {
        color: $lightBlue;
        font-size: $font-small;
        margin-left: 4px;
      }
    }
  }

  &__body {
    border-radius: 4px;
    border: 1px solid #d9d9d9;

    &-item {
      position: relative;

      &:nth-child(odd) {
        background-color: #f7f7f7;
      }

      &-total {
        background-color: $white;
        border-top: 1px solid #d9d9d9;

        p {
          font-weight: 700;
        }
      }
    }

    &-item,
    &-item-total {
      padding: 12px 14px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__edit {
    padding-right: 60px;
  }

  &__delete-icon {
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__item-empty {
    background-color: #f4f4f4;
    padding: 22px 0;

    p {
      font-size: $font-small;
      color: #a8a8a8;
      width: 100%;
      text-align: center;
    }
  }
}
