@use '../../../../scss/abstracts/' as *;
@forward './TablePoint/index';

.dashboard-refferal {
  width: 100%;
  // padding-bottom: 300px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;

  &__header {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .circle {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: $lightBlue;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h1 {
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__total-point {
    display: flex;
    align-items: center;
    column-gap: 14px;

    .icon {
      width: 21px;
      height: 21px;
    }

    p {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
