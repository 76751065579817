@use '../../../../scss/abstracts/' as *;

.transaction-recap {
  &__banner {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; // 50% lebar dikurang setengah dari gap samping
    margin-bottom: 24px;
    gap: 24px;
  }

  &__chart {
    max-height: 423px;
    margin-bottom: 20px;

    .g-table {
      .table-header {
        margin-bottom: 2px;
      }
      .input-field {
        height: 100%;
        background-color: #efefef;
        outline: none;
        border: none;
        padding: 8px 12px;
        border-radius: 3px;
        font-size: $font-small;
      }
    }

    &--total {
      color: #299b0a;
      padding-left: 36px;
      font-size: $font-large;
      margin-bottom: 25px;
    }

    &--chart {
      max-width: 95%;
      margin: 0 auto;
    }
  }

  &__tables {
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px); // 50% lebar dikurang setengah dari gap samping
    grid-template-rows: auto auto;
    gap: 29px 24px;

    .g-table {
      .table-header {
        margin-bottom: 2px;
      }

      h2 {
        font-weight: 400;
        padding-left: 36px;
        margin-bottom: 14px;
      }
    }

    .refund-table {
      background-color: $white;
      border-radius: 4px;
      font-family: $inter;
      min-width: 300px;
      padding: 18px 16px;

      .table {
        &-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }

        &-title {
          display: flex;
          gap: 20px;

          .title {
            .desc {
              display: flex;
              align-items: center;

              h1 {
                font-size: $font-medium;
                margin-left: 10px;
              }
            }
            p {
              font-size: $font-larger;
              padding-left: 36px;
            }
          }
        }

        &-body {
          min-height: 200px;

          .btn-icon {
            cursor: pointer;
          }

          .pre-white-space {
            white-space: pre;
          }
        }

        &-filter {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 18px;

          .link {
            color: $lightBlue;
            font-size: $font-small;
            font-weight: 500;
          }
        }
      }
    }
  }
}
