@use '../../../scss/abstracts/' as *;

.delivery-location {
  &-modal {
    width: 795px;
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 90px;
    max-height: 420px;
    margin-bottom: 80px;

    .content {
      &--left {
        .name-input {
          margin-bottom: 20px;
        }

        .select-wrapper {
          position: relative;
          .select-input {
            margin-bottom: 20px;
          }

          .checkbox-wrapper {
            display: inline-flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            font-size: $font-small;

            label {
              margin-left: 12px;
            }
          }
        }
      }

      &--right {
        .textarea-input {
          textarea {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  &-buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
    }
  }

  &-data {
    height: 60px;
    padding: 12px;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &-title,
  &-value {
    font-size: $font-small;
  }

  &-title {
    margin-bottom: 8px;
  }
}
