@use '../../../scss/abstracts/' as *;

.lds-ring-2 {
  // display: inline-block;
  @include flex(center, center);
  @include absolute();
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: $offWhite;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: $modal;
}
.lds-ring-2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 5px solid $lightBlue;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $lightBlue transparent transparent transparent;
}
.lds-ring-2 div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-2 div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-2 div:nth-child(3) {
  animation-delay: -0.15s;
}
