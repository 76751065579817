@use '../../../scss/abstracts/' as *;

.add-roles {
  &-modal {
    width: 634px;
    min-height: 150px;
  }

  &-content {
    margin-bottom: 24px;

    .select-permission {
      position: relative;

      .input-title {
        margin-bottom: 0;
      }

      &-field {
        background-color: $white;
        position: relative;
        width: 100%;
        height: 44px;
        border-radius: 4px;
        border: none;
        outline: 1px solid #e0e0e0;
        font-size: $font-small;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }
      }

      &-list {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        z-index: 10;
        max-height: 169px;
        overflow-y: auto;
        font-size: $font-small;

        &__title {
          padding: 12px 12px 4px 12px;
          font-size: 10px;
          font-weight: 700;
        }

        &__dropdown {
          list-style-type: none;
        }

        &__item {
          padding-left: 12px;
          padding-right: 12px;

          div {
            border-bottom: 1px solid #e0e0e0;
            padding-top: 18px;
            padding-bottom: 18px;
          }

          &:hover {
            background-color: $offWhite;
            cursor: pointer;
          }
        }
      }
    }
  }

  &-buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
    }
  }
}
