@use '../../../../../scss/abstracts/' as *;
@forward './DetailHarga/index';
@forward './DetailHarga/TableLocations/index';

.filter-wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  column-gap: 12px;

  .filter-by-location-type {
    .vehicles-filter__field {
      .vehicles-filter__field__input {
        width: 150px;
      }
    }
  }
}
