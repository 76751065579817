@use '../../../scss/abstracts/' as *;

.add-tour {
  &-modal {
    width: 960px;
    max-height: 567px;
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 90px;
    max-height: 420px;
    margin-bottom: 36px;

    .content {
      &--left {
        .name-input {
          margin-bottom: 20px;
        }

        .radio-input {
          margin-bottom: 32px;
          h3 {
            font-size: $font-small;
            margin-bottom: 14px;
          }

          .input-group {
            display: flex;
            // grid-template-columns: 1fr 1fr;
            // width: 345px;
            gap: 20px;
            justify-content: flex-start;
          }
        }
        .textarea-input {
          textarea {
            height: 181px;
            width: 100%;
          }
        }
      }

      &--right {
        .textarea-input {
          margin-bottom: 25px;

          textarea {
            height: 249px;
            width: 100%;
          }
        }
      }

      &--left .textarea-input,
      &--right .textarea-input {
        h3 {
          font-size: $font-small;
          margin-bottom: 14px;
        }

        textarea {
          resize: none;
          border-radius: 4px;
          border: none;
          outline: 1px solid #e0e0e0;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          padding: 9px 12px;
          font-weight: 500;
        }
      }
    }
  }

  &-buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
    }
  }

  &-data {
    height: 60px;
    padding: 12px;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &-title,
  &-value {
    font-size: $font-small;
  }

  &-title {
    margin-bottom: 8px;
  }
}
