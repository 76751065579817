@use '../abstracts/' as *;

body {
  font-family: $montserrat;
  font-size: $font-medium;
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  font-stretch: 100%;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: $font-xlarge;
}

h2,
.h2 {
  font-size: $font-larger;
}
h3,
.h3 {
  font-size: $font-label;
}

h4,
.h4 {
  font-size: $font-medium;
}
