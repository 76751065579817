@use '../../../../../../scss/abstracts/' as *;
@forward './TableLocations/index';

.detail-setting-harga {
  min-height: 950px;

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__body {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 46px;
    column-gap: 30px;

    .table-locations {
      grid-column: 1/3;
    }

    .action-button {
      grid-column: 2/3;
      display: flex;
      justify-content: flex-end;
      margin-top: 103px;
      margin-bottom: 80px;

      &__save {
        width: 267px;
      }
    }
  }
}
