@use '../../../scss/abstracts/' as *;

.select-field-overtime {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 12px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;

    span {
      display: flex;
      align-items: center;
      gap: 13px;

      &__input-value {
        border: none;
        outline: none;
        text-transform: capitalize;
        font-size: $font-small;
        font-weight: 700;
        cursor: default;

        &::placeholder {
          font-size: 12px;
        }
      }

      .placeholder {
        color: #757575;
        font-weight: 400;
      }

      span {
        font-size: $font-small;
        font-weight: 400;
        color: #1c3d5d;
        pointer-events: none;
      }
    }

    &__arrow {
      transition: all 0.3s ease;
    }
  }

  .show {
    transform: rotate(-180deg);
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 176px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;

    li:nth-child(1) {
      border-top: none;
    }

    li {
      // height: 44px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 11px 14px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      span:nth-child(1) {
        font-weight: 700;
        font-size: 12px;
        color: black;
        pointer-events: none;
      }

      span:nth-child(2) {
        font-weight: 400;
        font-size: 12px;
        color: #1c3d5d;
        pointer-events: none;
      }
    }

    li:hover {
      filter: brightness(95%);
    }
  }
}
