@use '../../../scss/abstracts/' as *;

.preview-image {
  @include flex(center, center);
  @include absolute();
  max-width: 550px;
  height: auto;
  max-height: 600px;
  z-index: $modal-overlay;
}
