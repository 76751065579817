@use '../../../scss/abstracts/' as *;

.card {
  background-color: $white;
  padding: 20px 50px;
  border-radius: 4px;
  position: relative;

  &__title {
    font-size: $font-medium;
    margin-bottom: 8px;
  }

  &__text {
    font-size: $font-larger;
    font-weight: 400;
  }

  &__icon {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 16px;
    top: 14px;
  }
}
