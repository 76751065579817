@use '../../../../../../scss/abstracts/' as *;

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 29px;
  row-gap: 36px;
  margin-bottom: 60px;

  .order-key {
    input {
      background-color: #f5f5f5;
      color: #0084ff;
      cursor: pointer;
    }

    input:hover {
      text-decoration: underline;
    }
  }

  .reason-canclation {
    textarea {
      height: 161px;
    }
  }

  .image-wrapper {
    position: relative;

    .button-preview {
      width: 28px;
      position: absolute;
      top: 37px;
      right: 14px;
    }
  }
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;

  button {
    width: 162px;
    height: 38px;
  }

  button.reject-btn {
    background: #ea2626;
  }

  button.process-btn {
    background: #007a05;
  }
}
