@use '../../../scss/abstracts/' as *;

.zone-price {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  padding: 28px 24px;
  border-radius: 10px;
  z-index: $modal;
  width: 320px;
  position: relative;

  &__head {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 14px;
  }

  &__desc {
    color: #000;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
  }

  &__category-price {
    margin-top: 4px;
    padding-left: 16px;

    li {
      color: #000;
      font-size: 12px;
      font-weight: normal;
    }
  }

  &__x-mark {
    color: #000;
    position: absolute;
    top: 20px;
    right: 14px;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
  }
}
