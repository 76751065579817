@use '../../../scss/abstracts/' as *;

.banner-modal {
  width: 795px;

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 40px;
    max-height: 420px;
    margin-bottom: 80px;
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  &__checkbox-periode {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    font-size: $font-small;

    label {
      margin-left: 12px;
    }
  }

  &__input {
    .field-wrapper {
      .upload {
        &__wrapper {
          border: none;
          outline: 1px solid #e0e0e0;
          font-size: $font-small;
          position: relative;
          width: 100%;
          border-radius: 4px;
          padding: 12px;
        }

        &__header {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          &-left {
            display: inline-flex;
            align-items: center;

            p {
              margin-left: 12px;
            }
          }

          &-right {
            height: 24px;

            &:hover {
              cursor: pointer;
            }
          }
        }

        &__thumbnail {
          margin-top: 12px;

          img {
            max-height: 300px;
            // margin: 0 auto;
          }
        }

        &__button {
          border: none;
          outline: 1px solid #e0e0e0;
          border-radius: 4px;
          padding: 2px 12px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .g-input {
    &:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  &-buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
    }
  }

  &__input-promo {
    opacity: 0.6;
    margin-bottom: 20px;
  }

  &__input-promo-label {
    display: flex;
    justify-content: space-between;
    font-size: $font-small;
    font-weight: bold;
  }

  &__checkbox-promo {
    display: flex;
    align-items: center;
    font-size: $font-small;

    label {
      font-weight: normal;
      margin-left: 12px;
    }
  }

  &-data {
    height: 60px;
    padding: 12px;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &-title,
  &-value {
    font-size: $font-small;
  }

  &-title {
    margin-bottom: 8px;
  }
}
