@use '../../../scss/abstracts/' as *;

.role-assignment {
  &-table {
    width: 100%;
    border-collapse: collapse;

    &__header {
      background-color: #f7f7f7;
      font-weight: bold;

      td.td-center {
        width: 124px;
      }
    }

    &__body {
      tr {
        &:nth-child(odd) {
          background-color: $white;
        }

        &:nth-child(even) {
          background-color: #f7f7f7;
        }
      }
      .tr-menu {
        position: relative;
        width: 100%;

        p {
          padding: 18px 24px;
        }

        .arrow-down-icon {
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);

          &:hover {
            cursor: pointer;
          }

          svg {
            transition: transform 0.1s;
          }

          .active {
            transform: rotate(180deg);
          }
        }
      }

      .tr-submenu {
        border-bottom: 1px solid #e0e0e0;

        tr {
          width: 100%;
        }

        input {
          width: 18px;
          height: 18px;
        }

        &.active {
          display: block;
          overflow: hidden;
        }

        &.hide {
          overflow: hidden;
        }

        .table-submenu {
          width: 100%;
          border-collapse: collapse;

          td.td-center {
            width: 124px;
          }
        }
      }

      .no-permission {
        text-align: center;

        td {
          padding: 50px 0;
        }
      }
    }

    .td-center {
      text-align: center;
      padding: 11px 0;
    }

    .br-left {
      border-left: 1px solid #e0e0e0;
    }

    .br-left-bottom {
      border-left: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
