@use '../../../scss/abstracts/' as *;

.notificationTemplate {
  width: calc(690px - 48px);
  max-height: calc(617px - 36px);

  // &__input {
  //   .input-text-title {
  //     font-weight: normal;
  //   }
  // }

  &__form {
    margin-top: 1.5rem;
  }

  &__form-group {
    margin-bottom: 5rem;

    & > * + * {
      margin-top: 1.5rem;
    }
  }

  &__message-area {
    label {
      display: block;
      font-size: $font-small;
      font-weight: 700;
      margin-bottom: 12px;
    }

    textarea {
      width: 100%;
      resize: none;
      height: 146px;
      padding: 9px 12px;
      outline: none;
      border: 1px solid #dbdbdb;
      border-radius: 6px;

      &:focus {
        border: 1px solid #0085ff;
        // outline-color: #0085ff;
      }
    }
  }

  &__button {
    display: flex;
    & > * ~ * {
      margin-left: 1rem;
    }

    .button {
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
}
