@use '../../../scss/abstracts/' as *;

.dummy-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  width: 400px;
  height: 400px;
  padding: 20px;
  z-index: $modal;
}
