@use '../../../scss/abstracts/' as *;

.language-modal {
  width: 586px;

  &-form {
    & > * + * {
      margin-top: 27px;
    }

    .action-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 12px;
    }
  }
}
