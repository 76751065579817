@use '../../../../scss/abstracts/' as *;
.card-template {
  width: 100%;
  border-radius: 10px;
  // box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &__image {
    border-radius: 10px 10px 0 0;
    img {
      width: 100%;
      max-height: 178px;
    }
  }

  &__content {
    padding: 15px 20px;
    h1 {
      font-size: 20px;
      margin-bottom: 11px;
    }
    h5 {
      color: $lightBlue;
      margin-top: 22px;
    }
    &-color-list {
      display: grid;
      grid-template-columns: repeat(5, 20px);
      align-items: center;
      position: relative;
      gap: 10px;
      &-color {
        // background: red;
        width: 24px;
        height: 24px;
        border-radius: 50px;
        cursor: pointer;
        &:hover ~ .primary-pallet {
          display: block;
        }
      }
      &-package {
        cursor: pointer;
        &:checked ~ .package-pallet {
          display: block;
        }
      }
    }
  }
}

.hide-primary-pallet {
  display: none;
}

.show-primary-pallet {
  display: block;
  // top: 25px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  // padding: 12px 16px;
  z-index: 1;
  &__content {
    display: block;
    width: 222px;
    padding: 14px 20px;
    // background-color: red;
    &-color {
      flex-direction: row;
      display: flex;
      cursor: pointer;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
    }
    // background-color: green;
  }
}

.hide-package-pallet {
  display: none;
}

.show-package-pallet {
  display: block;
  top: 25px;
  left: 125px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  // padding: 12px 16px;
  z-index: 1;
  &__content {
    display: block;
    width: 222px;
    // background-color: red;
    padding: 14px 20px;
    &-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 30px);
      row-gap: 20px;
    }
    &-color {
      flex-direction: row;
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 50px;
    }
    span {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
    }
    // background-color: green;
  }
}
