@use '../../../../scss/abstracts/' as *;
@forward './Lokasi/DetailLokasi/detail-lokasi';
@forward './Service/DetailService/detail-service';
@forward './LanguageEditor/language-editor';

.term-and-condition {
  position: relative;

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
