@use '../../../scss/abstracts/' as *;

.add-car {
  background: $white;
  border-radius: 6px;

  &__wrapper {
    width: 393px;
    max-height: 32rem;
    overflow-y: auto;
    padding: 0 0.5rem;
    // background-color: teal;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: $font-medium;

    & > svg {
      margin-right: 10px;
    }
  }

  &__form-group {
    & > * + * {
      margin-top: 1.75rem;
    }
  }

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__dropdown {
    position: relative;
    width: 100%;
    display: block;
    padding: 0;

    select {
      width: 100%;
      margin: 0;
      background: none;
      border: none;
      outline: 1px solid #e0e0e0;
      border-radius: 4px;
      // outline: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      appearance: none;
      -webkit-appearance: none;
      font-size: 12px;
      height: 44px;
      color: #000000;
      padding: 0.6em 3em 0.5em 0.8em;
      line-height: 1.3;

      &:focus {
        outline-color: #0085ff;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      top: calc(50% - 5px);
      right: 0.7em;
      margin-top: -4px;
      z-index: 2;
      background: url('../../../../public/assets/icon/chevron-down.svg') no-repeat;
      pointer-events: none;
    }
  }

  &__license-input-group {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    column-gap: 8px;
  }

  &__max-select-group {
    display: grid;
    grid-template-columns: 98px auto;
    column-gap: 8px;
  }

  &__checkbox-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 1rem;
    column-gap: 1.5rem;
  }

  &__checkbox-item {
    display: flex;
    align-items: center;

    label {
      display: inline-block;
      font-size: $font-small;
      margin-left: 8px;
    }

    input[type='checkbox'] {
      width: 15px;
      height: 15px;
    }
  }

  &__btn-action {
    margin-top: 2.5rem;
    margin-bottom: 5px;
  }
}
