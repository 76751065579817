@use '../../../../scss/abstracts/' as *;
@forward './Daerah/index';
@forward './Harga/index';
@forward './Harga/DetailHarga/index';
@forward './Harga/DetailHarga/TableLocations/index';

.airport-transfer-car {
  position: relative;

  .button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
