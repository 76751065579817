@use '../../../../scss/abstracts/' as *;

.services-form {
  width: 100%;
  padding: 38px 0 0 0;

  &__active-checkbox {
    width: 100%;
    display: flex;
    align-items: start;
    column-gap: 20px;
    margin-bottom: 39px;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 18px;

    &__column {
      position: relative;

      .config-price {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        column-gap: 32px;

        .status-info {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-right: 60px;

          h3 {
            font-size: $font-small;
            line-height: normal;
          }

          input:checked + .slider {
            background-color: #009ef7;
          }
        }
      }
    }
  }
}
