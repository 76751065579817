@use '../scss/base';
@use '../components/Global/' as *;
@use '../components/Modals/' as *;

@use '../components/HomeComponent';
@use '../pages/' as *;
@use '../components/LoginComponent';
// @use '../components/RegisterComponent';
// @use '../components/VerificationComponent';

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
