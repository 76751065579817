@use '../../../scss/abstracts/' as *;

.modal {
  .add-violation {
    width: 360px;

    &__header {
      margin-bottom: 22px;

      h1 {
        font-size: $font-title;
      }
    }

    &__fine-name {
      margin-bottom: 14px;
    }

    &__fine-detail {
      margin-bottom: 26px;
    }
  }
}
