@use '../../../../scss/abstracts/' as *;

.transaction-without-driver {
  &__wrapper {
    background-color: $white;
    border-radius: 4px;
    font-family: $inter;
    min-width: 300px;
    padding: 18px 16px;
    min-height: 85vh;
    margin-top: 14px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  &__filter {
    display: flex;

    .filter-location {
      margin-right: 8px;
    }
  }

  .table-wrapper {
    width: 120%;

    thead.table-head {
      tr {
        th {
          .sorted-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 4px;
            cursor: pointer;

            div.chevrons {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
