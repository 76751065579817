@use '../../../../../scss/abstracts/' as *;

.card-user-stats {
  width: 100%;
  background-color: $white;
  padding: 0 21px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .value {
    width: auto;

    h2 {
      font-size: $font-small;
      font-weight: 500;
      margin-bottom: 9px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
    }

    ul {
      margin-top: 23px;
      width: 90px;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      list-style: none;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 5px;

        div {
          width: 11px;
          height: 7px;
          border-radius: 2px;
        }

        span {
          font-size: 8px;
          font-weight: 500;
        }
      }
    }
  }

  .chart {
    width: 60%;
  }
}

.card-user-stats-skeleton {
  width: 100%;
  background: #fcfcfc;
  padding: 0 21px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .value-skeleton {
    width: auto;

    div.title-skeleton {
      width: 80px;
      height: 18px;
      background: #eae9e9;
      margin-bottom: 9px;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    div.total-skeleton {
      width: 60px;
      height: 30px;
      background: #eae9e9;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    ul {
      margin-top: 23px;
      width: 90px;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      list-style: none;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 5px;

        div {
          width: 11px;
          height: 7px;
          background: #eae9e9;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        span {
          width: 80px;
          height: 7px;
          background: #eae9e9;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      }
    }
  }

  .chart-skeleton {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    .doughnut {
      width: 130px;
      height: 130px;
      border-radius: 100%;
      background: #eae9e9;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
