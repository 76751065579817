@use '../../../../scss/abstracts/' as *;

.table-searchbar {
  .search {
    &-field {
      width: 315px;
      height: 30px;
      border-radius: 3px;
      background: $white;
      border: 1px solid #dcdcdc;
      font-size: $font-small;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-weight: 500;

      &:focus {
        outline: none;
      }
    }
  }

  .field-wrapper {
    position: relative;

    .search-icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
