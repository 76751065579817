@use '../../../../../scss/abstracts/' as *;
@forward './SchedulePriceDetail/index';
@forward './HargaPotonganDetail/index';

.detail-schedule-page {
  position: relative;
  min-height: 800px;

  &__header {
    width: 87px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 21px;

    span {
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
