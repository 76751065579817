@use '../../../../scss/abstracts/' as *;

.filter {
  &-title {
    margin-left: 8px;
  }

  &-modal {
    position: absolute;
    transform: translate(-60%, 0);
    background: $white;
    width: 300px;
    // height: 153px;
    margin-top: 10px;
    z-index: $modal;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    .line {
      border: 0.5px solid #ececec;
    }
  }
}
