@use '../../../../../../scss/abstracts/' as *;

.g-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: $font-small;
  display: flex;
  align-items: center;
  width: fit-content;

  .checkmark {
    display: block;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background-color: #e4e4e4;

    &::after {
      content: '';
      position: absolute;
      display: none;
    }

    &:after {
      left: 0;
      top: 0;
      background-position: center 5px;
      width: 15px;
      height: 15px;
      background-image: url('../../../../../../icons/check-icon.svg');
      background-repeat: no-repeat;
    }
  }

  label {
    margin-left: 8px;
    cursor: pointer;
  }

  input {
    &:checked ~ .checkmark {
      background-color: #0085ff;
    }

    &:checked ~ .checkmark::after {
      display: block;
    }
  }
}
