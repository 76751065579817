@use '../../../scss/abstracts/' as *;

.select-field-time-with-driver {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    gap: 12.5px;
    padding: 0 12px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;

    &__input {
      width: 97%;
      border: none;
      outline: none;
      height: 44px;
      font-size: $font-small;
      background: transparent;
      cursor: default;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__input:focus & {
      outline-color: #0085ff;
    }
  }

  .disable {
    background-color: #f5f5f5;
    pointer-events: none;
  }

  &__dropdown {
    position: absolute;
    padding: 13px 14px;
    left: 0;
    top: 85px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(35, 32, 32, 0.1);
    border-radius: 5px;
    z-index: 10;

    &__container {
      font-size: $font-small;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      justify-content: space-between;
      row-gap: 4px;
      column-gap: 4px;

      &__jam {
        h4 {
          margin-bottom: 10px;
          font-size: $font-small;
        }

        ul {
          overflow-y: auto;
          max-height: 189px;
          height: 100%;
          border-radius: 3px;
          text-align: center;
          border: 1px solid #e0e0e0;

          .list-hour {
            cursor: pointer;
            padding: 5px 0;
            text-align: center;
          }

          .list-hour:hover {
            background-color: #f5f6fa;
          }

          .active {
            background-color: #f5f6fa;
          }

          .disable-hour {
            pointer-events: none;
            color: #6666;
          }
        }
      }

      &__menit {
        h4 {
          margin-bottom: 10px;
          font-size: $font-small;
        }

        ul {
          overflow-y: auto;
          max-height: 189px;
          height: 189px;
          border-radius: 3px;
          border: 1px solid #e0e0e0;
          text-align: center;

          .list-minute {
            cursor: pointer;
            padding: 5px 0;
            text-align: center;
          }

          .list-minute:hover {
            background-color: #f5f6fa;
          }

          .active {
            background-color: #f5f6fa;
          }
        }
      }

      &__selesai {
        width: 100%;
        text-align: right;
        color: #f1a33a;

        h3 {
          display: inline-block;
          width: 100%;
          cursor: pointer;
          font-size: $font-small;
        }
      }
    }
  }
}
