@use '../../../scss/abstracts/' as *;

.progress-bar {
  display: flex;
  align-items: center;
  gap: 10px;

  &__left-arrow {
    cursor: pointer;
  }

  &__page-container {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 12px;

    &__page {
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 0px;
      gap: 12px;

      .bar {
        background-color: #dbdbdb;
        width: 21px;
        height: 2px;
        border-radius: 10px;
        overflow: hidden;

        div {
          width: 100%;
          height: 100%;
          background-color: #1c3d5d;
          transform: translateX(-100%);
          transition: all 0.2s ease;
        }
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0px;
        gap: 12px;

        &__number {
          width: 28px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #c8c8c8;
          color: white;
          border-radius: 100%;
          font-size: 12px;
          font-weight: 600;
          transition: all 0.5s ease;
        }

        &__title {
          color: #909090;
          font-weight: 700;
          font-size: 14px;
          transition: all 0.5s ease;
        }
      }
    }

    .active {
      .bar {
        div {
          transform: translateX(0);
        }
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0;
        gap: 12px;

        &__number {
          background-color: #1c3d5d;
        }

        &__title {
          color: black;
        }
      }
    }
  }
}
