@use '../../../scss/abstracts/' as *;

.transaction {
  &-modal {
    width: 960px;
    max-height: 616px;
  }

  &-detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 10px;
    max-height: 420px;
    margin-bottom: 78px;

    &--left {
      overflow-y: auto;
      max-height: 450px;
    }

    &--center {
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
      padding: 0 10px;
      overflow-y: auto;
      max-height: 450px;

      &--extend {
        margin-top: 31px;
        header {
          margin-bottom: 9px;
          h3 {
            font-size: $font-small;
            color: #0085ff;
          }
        }
      }
    }

    &--right {
      .transaction-data {
        // &:last-child {
        .transaction-value {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .confirm-btn {
            color: $lightBlue;
            cursor: pointer;
          }
        }
        // }
      }
    }
  }

  &-buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }

  &-data {
    // height: 60px;
    padding: 12px;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &-title,
  &-value {
    font-size: $font-small;
  }

  &-title {
    margin-bottom: 8px;
  }

  &-identity {
    margin-top: 0.875rem;

    & > * ~ * {
      margin-top: 11px;
    }

    &--image {
      position: relative;
      width: 100%;
      height: 138px;

      img {
        width: 100%;
        height: 100%;
      }

      svg {
        position: absolute;
        bottom: 12px;
        right: 12px;
        cursor: pointer;
      }
    }
  }
}
