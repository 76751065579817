@use '../../../scss/abstracts/' as *;
@forward './ZoneListForm/index';

.booking-zone {
  width: 100%;

  &__empty-data {
    width: 100%;
    height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #f4f4f4;
    color: #a8a8a8;
    font-size: 12px;
    font-weight: 400;
  }

  &__form {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
}
