@use '../../../scss/abstracts/' as *;

.upload-vehicle-image {
  &__container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 12px 18px;
    border-radius: 9px;
    position: relative;

    .button {
      font-size: $font-small;
      text-align: center;
      width: 80px;
      padding: 50px 0;

      div {
        border-radius: 9px;
        height: 165px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed #cbcbcb;
      }

      p {
        color: #757575;

        button {
          background-color: transparent;
          color: #0085ff;
          font-weight: 700;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }
    }

    .active {
      background-color: #eee;
    }

    .drag-file-element {
      position: absolute;
      width: 100%;
      height: calc(165px + 12px);
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    &.disable {
      background-color: #f5f5f5;

      button {
        pointer-events: none;
      }
    }
  }

  &__thumbnails {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    text-align: center;

    & > *:nth-child(odd) {
      border-right: 1px solid #cbcbcb;
    }

    .thumbnail {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px 0;

      .image {
        width: 29px;
        height: 29px;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          display: block;
        }
      }

      .filename {
        font-weight: 500;
        font-size: $font-small;
        margin-bottom: 0;
      }

      .icon {
        cursor: pointer;
        svg {
          display: block;
        }
      }
    }
  }
}
