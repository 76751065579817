@use '../../../scss/abstracts/' as *;

.preview-image-identity {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .overlay {
      width: 100%;
      height: 100%;
      background: rgba($color: #000, $alpha: 0.6);
    }

    .img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 550px;
      height: auto;
    }
  }
}
