@use '../../../scss/abstracts/' as *;

.select-field-search {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    position: relative;
    width: 100%;
    padding: 0 14px;
    min-height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__input {
      width: 100%;
      min-height: 44px;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;
    }

    &__chevron {
      transform: rotate(0deg);
      transition: all ease 0.3s;
    }

    .down {
      transform: rotate(-180deg);
    }

    &__dropdown {
      position: absolute;
      left: 0;
      right: 0;
      top: 50px;
      max-height: 176px;
      border-radius: 9px;
      background-color: white;
      box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
      list-style: none;
      overflow-y: auto;
      z-index: 10;

      li {
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 11px 14px;
        gap: 14px;
        border-top: 1px solid #e0e0e0;
        background-color: white;
        cursor: pointer;
        font-size: 12px;
      }
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }
}
