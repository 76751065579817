@use '../../../../../../scss/abstracts/' as *;

.airport-transfer-form {
  display: flex;
  flex-direction: column;

  &__rental-location {
    margin-bottom: 35px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    row-gap: 38px;
    column-gap: 28px;

    &__shuttle {
      position: relative;
      grid-column: 1/3;
      display: flex;
      justify-content: space-between;
      gap: 28px;

      div {
        width: 100%;
      }

      .switch {
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }

      .switch:hover {
        filter: brightness(95%);
      }
    }

    &__baggage-passenger {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    &__pick-up-singapore {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    &__baggage-and-flight-number {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    &__total-price {
      grid-column: 2 / 3;
      grid-row: 6 / 7;
      background: #f5f6fa;
      padding: 9px 24px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      border-radius: 5px;

      h3 {
        font-size: 10px;
        font-weight: 400;
      }

      p {
        font-size: 14px;
        font-weight: 700;
      }
    }

    &__location-map {
      position: relative;
      grid-column: 1 / 3;
      display: flex;
      align-items: center;
      column-gap: 28px;

      .input-field-map {
        width: 100%;
      }

      .switch {
        position: absolute;
        top: 11%;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
      }

      .switch:hover {
        filter: brightness(95%);
      }
    }
  }

  // &__additional-item {
  //   width: 100%;
  //   margin-bottom: 36px;

  //   h2 {
  //     color: #009ef7;
  //     font-size: 16px;
  //     font-weight: 700;
  //     margin-bottom: 22px;
  //   }

  //   div.wrapper {
  //     display: flex;
  //     flex-direction: column;
  //     row-gap: 22px;

  //     .input-text-area-title {
  //       margin-bottom: 4px;
  //     }
  //   }
  // }

  &__button-action {
    margin: 0 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;

    &__action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
