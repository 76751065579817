@use '../../../scss/abstracts/' as *;

.refund-transfer-modal {
  min-width: 610px;

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .input-wrapper {
    position: relative;
    margin-top: 27px;
    margin-bottom: 37px;

    .buttons {
      position: absolute;
      top: 39px;
      right: 6px;
      display: flex;
      align-items: center;
      column-gap: 6px;

      .browse,
      .delete,
      .view {
        font-size: 12px;
        font-weight: normal;
        border-radius: 4px;
      }

      .browse {
        color: black;
        background-color: #e0e0e0;
      }

      .delete {
        color: #ea2626;
        background-color: rgba(234, 38, 38, 0.15);
      }
    }
  }

  .action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
  }
}
