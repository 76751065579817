@use '../../../scss/abstracts/' as *;

.price-difference {
  &-modal {
    width: 658px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0 40px;
    max-height: 476px;
    overflow-y: auto;
    padding: 4px 0;
    row-gap: 20px;
    padding-right: 2px;
    overscroll-behavior-y: contain;
    font-size: 12px;

    table {
      border-radius: 8px;
      border-spacing: 0;
      border-collapse: separate;
      border: 1px solid #e0e0e0;

      tr {
        td {
          border: 1px solid #e0e0e0;
          padding: 14px 40px;
        }

        td:last-child {
          text-align: right;
        }
      }
    }

    table,
    th,
    td {
      font-weight: bold;
    }
  }

  &__button {
    margin-top: 36px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }
}
