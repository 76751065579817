@use '../../../scss/abstracts/' as *;

.select-field-passenger-and-luggage {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    width: 100%;
    height: 44px;
    background: cyan;
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 0 12px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    background-color: white;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px 14px;
    right: 0;
    left: 0;
    top: 116%;
    z-index: 90;

    &__list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
        column-gap: 10px;

        p {
          width: 75px;
          font-size: 12px;
        }
      }

      &__operations {
        display: flex;
        align-items: center;
        column-gap: 10px;

        button.button-operation {
          background-color: transparent;
          border: none;
          cursor: pointer;

          span {
            pointer-events: none;
          }

          .counter-icon {
            background-color: transparent;
            color: #1c3d5d;
          }
        }

        button.disable {
          background-color: transparent;
          cursor: not-allowed;
          pointer-events: none;

          .counter-icon {
            color: #eaeaea;
          }
        }
      }
    }
  }
}
