@use '../../../scss/abstracts/' as *;

.add-roles-menu {
  &-modal {
    width: 634px;

    &__header {
      display: flex;

      .header-button {
        text-align: center;
        min-width: 132px;
        border-radius: 6px;
        background-color: #f2f2f2;
        padding: 10px 0;
        font-size: $font-small;
        transition: background-color 0.1s;
        margin-bottom: 24px;

        &:hover {
          cursor: pointer;
          background-color: #e0e0e0;
        }

        &:last-child {
          margin-left: 12px;
        }

        &.active {
          background-color: $lightBlue;
          font-weight: 700;
          color: $white;
        }
      }
    }
  }

  &__form {
    .select-permission {
      margin-bottom: 18px;
      position: relative;

      .input-title {
        margin-bottom: 0;
      }

      &-field {
        background-color: $white;
        position: relative;
        width: 100%;
        height: 44px;
        border-radius: 4px;
        border: none;
        outline: 1px solid #e0e0e0;
        font-size: $font-small;
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }
      }

      &-list {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        z-index: 10;
        max-height: 169px;
        overflow-y: auto;
        font-size: $font-small;

        &__title {
          padding: 20px 16px 12px 16px;
          font-size: $font-small;
          font-weight: 700;
        }

        &__dropdown {
          list-style-type: none;
        }

        &__item {
          padding: 10px 16px;

          &:hover {
            background-color: $offWhite;
            cursor: pointer;
          }
        }
      }
    }

    .input-permission {
      &-title {
        display: block;
        font-size: $font-small;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &-checkbox {
        color: $darkGray;
        font-size: $font-small;
        display: inline-flex;
        margin-right: 24px;

        input {
          margin-left: 12px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__input {
    margin-bottom: 48px;
  }

  &__buttons {
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
    }
  }
}
