.voucher {
  position: relative;

  &-detail {
    &__back-btn {
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      cursor: pointer;
      margin-bottom: 21px;

      span {
        margin-left: 0.75rem;
      }
    }

    &__form {
      margin-bottom: 24px;

      .form__four-column {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        row-gap: 24px;
        align-items: end;
        margin-bottom: 40px;

        .end-date {
          .select-field-date__dropdown {
            top: 40px;
          }
        }

        .input-group {
          &__voucher-discount {
            h4 {
              font-size: 12px;
              margin-bottom: 12px;
            }

            .radio-group {
              display: flex;
              column-gap: 20px;
              margin-bottom: 14px;
            }
          }
        }

        &__vehicles {
          grid-column: 1/3;
        }
      }

      .form__two-column {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 24px;
        align-items: start;
        margin-bottom: 34px;

        .text-area-input {
          label {
            margin-bottom: 0;
          }

          .field-wrapper {
            .input-text-area-field {
              height: 208px;
            }
          }
        }

        .input-group__banner-input {
          h4 {
            font-size: 12px;
            margin-bottom: 12px;
            line-height: 18px;
          }

          .upload-image-custom-order__container {
            height: 208px;

            .upload-image-custom-order__container__thumbnails {
              height: 184px;
            }
          }
        }
      }

      .input-group__text-editor {
        margin-bottom: 24px;

        h4 {
          font-size: 12px;
          margin-bottom: 12px;
          line-height: 18px;
        }

        .text-area-editor__text-area {
          height: 205px;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: end;
      column-gap: 12px;
    }

    .btn-draft {
      background-color: #f1a33a;
    }
  }

  .table {
    overflow-x: auto;

    .table-wrapper {
      width: 105%;
    }

    [data-type='name'] {
      max-width: 162px;
    }

    [data-type='status'] {
      width: 132px;
    }
  }
}
