@use '../../../scss/abstracts/' as *;

.select-field-additional {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    width: 100%;
    padding: 0 12px;
    height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;

    &__input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;
      cursor: default;

      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 365px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;
    box-sizing: border-box;

    li:first-child {
      border-top: none;
    }
    li:last-child {
      border-bottom: none;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 5px;
      padding: 22px;
      border-bottom: 1px solid #e0e0e0;
      box-sizing: border-box;

      div:nth-child(2) {
        background-color: #dbffde;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #299b0a;
        font-size: 10px;
        font-weight: 400;
        padding: 0 10px;
        height: 21px;
        border-radius: 100px;
      }

      div:last-child {
        margin-left: auto;
        display: flex;
        align-items: center;
        column-gap: 10px;

        span:nth-child(2) {
          color: #1c3d5d;
          font-size: 12px;
          font-weight: 400;
        }

        .quantity-icon {
          cursor: pointer;
        }
      }
    }
  }
}
