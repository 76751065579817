@use '../../../../scss/abstracts/' as *;

.roles {
  &-detail {
    &__back-btn {
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 22px;

      span {
        margin-left: 0.75rem;
      }
    }

    &__header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      background-color: $white;
      border-radius: 6px 6px 0 0;
    }

    &__content {
      padding: 18px 24px;
    }

    &__body {
      border-radius: 0 0 6px 6px;
      background-color: $white;
      margin-bottom: 26px;

      .roles-detail__content {
        min-height: 700px;
      }

      &-add {
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 20px;

        p {
          color: $lightBlue;
          font-size: $font-small;
          margin-left: 4px;
        }
      }
    }

    &__button {
      text-align: right;

      button {
        display: inline-block;
      }
    }
  }

  .g-table {
    min-height: 500px;
    position: relative;
  }
}
