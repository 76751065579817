@use '../../../scss/abstracts/' as *;

.select-field-vehicle {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    width: 100%;
    max-width: 100%;
    // padding: 0 14px;
    // min-height: 44px;
    height: 44px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    cursor: pointer;

    scrollbar-width: 40px;
    scrollbar-color: #e0e0e0;

    /* Menyelaraskan tampilan scrollbar */
    &::-webkit-scrollbar {
      height: 4px; /* Tinggi scrollbar horizontal */
      width: 40px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* Warna track scrollbar */
      margin-right: 20px;
      margin-left: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e0e0e0; /* Warna thumb scrollbar */
      border-radius: 10px; /* Radius untuk sudut bulat */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
    }

    // for Firefox
    &::-moz-scrollbar {
      width: 40px;
      height: 4px;
    }

    &::-moz-scrollbar-track {
      background: transparent; /* Warna track scrollbar */
      margin-right: 20px;
      margin-left: 20px;
    }

    &::-moz-scrollbar-thumb {
      background: #e0e0e0; /* Warna thumb scrollbar */
      border-radius: 10px; /* Radius untuk sudut bulat */
    }

    &::-moz-scrollbar-thumb:hover {
      background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
    }

    &__input {
      width: 100%;
      min-height: 44px;
      border: none;
      outline: none;
      background: transparent;
      font-size: $font-small;
      padding: 0 14px;
    }

    &__car {
      // min-height: 44px;
      height: 44px;
      width: 100%;
      // padding: 8px 0;
      padding: 0 14px;
      display: flex;
      // flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center;
      // gap: 20px;
      background-color: transparent;
      pointer-events: none;

      span.name {
        height: 28px;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        gap: 6px;
        align-items: center;
        border-radius: 100px;
        padding: 0 20px;
        font-size: 10px;
        margin-right: 20px;

        span {
          width: 15px;
          height: 15px;
          background-color: #009ef7;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          font-size: 12px;
        }
      }
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    // min-width: 536.6px;
    width: 100%;
    position: absolute;
    left: 0;
    // right: 0;
    max-height: 365px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;
    top: 88px;

    label {
      height: 89px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 14px 21px;
      gap: 21px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      div:nth-child(1) {
        width: 61px;
        height: 61px;
        background-color: #e0e0e0;
        border-radius: 3px;
        overflow: hidden;
        // pointer-events: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      div:nth-child(2) {
        height: 61px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // pointer-events: none;
        gap: 8px;

        h3 {
          font-size: $font-small;
          font-weight: bold;
          text-transform: capitalize;
        }

        span:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 12px;

          p {
            font-size: $font-small;
            font-weight: normal;
            text-transform: capitalize;
          }

          span {
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background: #000;
          }
        }
      }

      div:last-child {
        margin-left: auto;
      }
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: red;

    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    // opacity: 0;
    cursor: pointer;

    &:hover:disabled {
      cursor: not-allowed;
    }
  }

  &__not-found {
    padding: 11px 14px;
  }
}
