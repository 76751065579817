@use '../../../scss/abstracts/' as *;

.assign-driver {
  width: 566px;
  display: flex;
  flex-direction: column;
  gap: 23px;

  &__action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
