@use '../../../../../scss/abstracts/' as *;

.create-overtime {
  // width: 918px;
  height: auto;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;

  &__input-overtime {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 41px;
    margin-bottom: 32px;
  }

  &__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 41px;
    row-gap: 20px;
    margin-bottom: 122px;
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
  }

  &__select-container {
    position: relative;
    width: 100%;
    display: inline-block;
    align-self: flex-end;

    .options-container {
      display: block;
    }
  }

  &__select-label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 16px;
  }

  &__select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    // background-color: red;

    &.disabled {
      pointer-events: none;
      background-color: #f5f5f5;
    }
  }

  &__options-container {
    display: none;
    margin-top: 4px;
    width: 100%;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;

    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #e0e0e0;

      &:nth-child(1) {
        border-top: none;
      }
    }

    .option input {
      // margin-right: 10px;
      width: 18px;
      height: 18px;
    }

    .option label {
      font-size: 12px;
    }

    .option.option-empty {
      display: flex;
      justify-content: center;
      font-size: 12px;
    }

    &.show {
      display: block;
    }
  }

  &__arrow {
    transform: rotate(0deg);
    transition: all ease 0.3s;
  }

  .arrow-down {
    transform: rotate(-180deg);
  }

  &__btn-group {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}
