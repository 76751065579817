.down-payment {
  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__input-group {
    .input-field,
    .select-field-dropdown,
    .select-field-vehicle {
      margin-bottom: 24px;

      .disable {
        background: #f5f5f5;
      }
    }

    .form-field__formula {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;

      h3 {
        color: #000;
        font-size: 12px;
        font-weight: 700;
      }

      &__items {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        row-gap: 14px;
        align-items: center;
      }

      .form-field__formula-field {
        position: relative;
        width: 100%;
        min-height: 44px;
        border-radius: 4px;
        border: none;
        outline: 1px solid #e0e0e0;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        font-weight: 500;
        background-color: #fff;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        .formula-item {
          height: 28px;
          background-color: #f2f2f2;
          display: flex;
          font-size: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          padding: 0 20px;
          width: fit-content;
        }

        .formula-placeholder {
          opacity: 0.6;
        }
      }
    }

    .form-field__threshold-value,
    .form-field__percentage {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 35px;
      border-top: 1px;
      border-bottom: 1px;
      border-left: 0;
      border-right: 0;
      border-color: #bbbbbb;
      border-style: dashed;
      padding: 28px 0 32px 0;
      margin-bottom: 24px;

      .form-with-label {
        margin-bottom: 16px;
      }
    }
  }

  &__buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;
    }
  }

  &__car-input {
    border-top: 1px;
    border-bottom: 1px;
    border-left: 0;
    border-right: 0;
    border-color: #bbbbbb;
    border-style: dashed;
    padding: 28px 0 32px 0;

    .select-field-vehicle__dropdown {
      margin-top: 30px;
    }
  }

  &__prices {
    padding-bottom: 24px;
    border-top: 0;
    border-bottom: 1px;
    border-left: 0;
    border-right: 0;
    border-color: #bbbbbb;
    border-style: dashed;

    .btn-simulate {
      margin-left: auto;
      margin-right: 0;
      background-color: #d3efff;
      color: #0084ff;
      font-weight: bold;
    }
  }

  .form-field__percentage {
    border-bottom: none;
    margin-bottom: 0;
  }
}
