@use '../../../../scss/abstracts/' as *;
.detail-task {
  &__back-btn {
    display: flex;
    width: fit-content;
    margin-bottom: 21px;

    &:hover {
      cursor: pointer;
    }

    p {
      margin-left: 12px;
      font-size: $font-medium;
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__return-detail,
  &__garage-detail,
  &__delivery-detail {
    &-title {
      margin-bottom: 18px;

      h1 {
        color: $lightBlue;
        font-size: $font-label;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: flex-end;

      &__rent-date {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }

      &__phone-number {
        align-items: flex-end;
        gap: 12px;
        display: grid;
        grid-template-columns: 20% auto;

        &-wrapper {
          position: relative;
        }
      }

      &__image-wrapper {
        position: absolute;
        right: 0;
        top: 0;
      }

      &__payment-method {
        &.confirmation {
          display: grid;
          grid-template-columns: auto 30%;
          align-items: flex-end;
          gap: 12px;
        }

        &.not-confirmation {
          display: block;
        }
      }

      &__preview-image {
        position: relative;

        .preview-image-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .preview-image-input {
          .input-text-field {
            padding-right: 100px;
          }
        }
      }

      &__total-payment {
        margin-top: 28px;
      }

      &__notes {
        grid-column: span 2 / span 2;
      }

      &__input-violation {
        grid-column: span 2 / span 2;
      }
    }

    .upload-image__wrapper {
      grid-column: span 2 / span 2;
    }

    .upload-image__thumbnails {
      grid-column: 1;
    }
  }

  &__renter-detail,
  &__rent-detail,
  &__payment-detail,
  &__return-detail,
  &__delivery-detail {
    margin-bottom: 42px;
  }

  &__transaction-buttons {
    margin-top: 60px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }
}
