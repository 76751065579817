@use '../../../scss/abstracts/' as *;

.g-radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: $font-small;
  align-items: center;
  display: flex;
  width: fit-content;

  .bullet {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #e4e4e4;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      height: 70%;
      background-color: #e4e4e4;
      border-radius: 100%;
    }
  }

  label {
    margin-left: 8px;
    cursor: pointer;
  }

  input {
    &:checked ~ .bullet {
      border-color: #0085ff;
    }

    &:checked ~ .bullet::after {
      background-color: #0085ff;
    }
  }
}
