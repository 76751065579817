@use '../../../../scss/abstracts/' as *;

.about-us {
  .g-table {
    min-height: 684px;
  }

  &-header {
    padding: 0 24px;
    margin-bottom: 38px;

    h3 {
      font-size: $font-small;

      &:first-of-type {
        margin-bottom: 14px;
      }
    }
  }

  &-body {
    padding: 0 24px;
    display: flex;
    gap: 36px;
    flex-wrap: wrap;
  }

  &-item {
    width: fit-content;
    padding: 14px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;

    &__image-wrapper {
      max-width: 285px;
      margin-bottom: 18px;
    }

    &__icons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      img:hover {
        cursor: pointer;
      }
    }
  }
}
