@use '../../../scss/abstracts/' as *;

.additional-images {
  width: 546px;

  &__show-image {
    width: 100%;
    height: 502px;
    border-radius: 8px;
    position: relative;

    .image-wrapper {
      width: 100%;
      height: 100%;
      border-radius: 8px;

      .image {
        width: 100%;
        height: 100%;
        pointer-events: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }
    }
  }

  &__image-list {
    width: 100%;
    padding-bottom: 6px;
    margin-top: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    scrollbar-width: 40px;
    scrollbar-color: #e0e0e0;

    /* Menyelaraskan tampilan scrollbar */
    &::-webkit-scrollbar {
      height: 4px; /* Tinggi scrollbar horizontal */
      width: 40px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* Warna track scrollbar */
      margin-right: 20px;
      margin-left: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e0e0e0; /* Warna thumb scrollbar */
      border-radius: 10px; /* Radius untuk sudut bulat */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
    }

    // for Firefox
    &::-moz-scrollbar {
      width: 40px;
      height: 4px;
    }

    &::-moz-scrollbar-track {
      background: transparent; /* Warna track scrollbar */
      margin-right: 20px;
      margin-left: 20px;
    }

    &::-moz-scrollbar-thumb {
      background: #e0e0e0; /* Warna thumb scrollbar */
      border-radius: 10px; /* Radius untuk sudut bulat */
    }

    &::-moz-scrollbar-thumb:hover {
      background: #f1f1f1; /* Warna thumb scrollbar saat dihover */
    }

    .image-wrapper {
      display: flex;
      align-items: center;
      column-gap: 9px;

      .image {
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        flex: 0 0 auto;

        img {
          width: 93px;
          height: 100%;
          object-fit: cover;
          filter: brightness(80%);
          pointer-events: none;
        }
      }

      .active {
        img {
          filter: brightness(100%);
        }
      }
    }
  }
}
