@use '../../../../../../scss/abstracts/' as *;

.detail-driver-task {
  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    margin-top: 28px;
  }

  &__order-detail,
  &__driver-detail {
    margin-top: 22px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 29px;
    row-gap: 36px;

    .rent-period,
    .rent-payment-type,
    .rent-baggage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 12px;

      div {
        width: 100%;
      }
    }

    .identity-image-sim,
    .identity-image-ktp {
      h3 {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 14px;
      }

      .image {
        width: 100%;
        height: 164px;
        background: white;
        border: 1px solid #e0e0e0;
        border-radius: 9px;
        padding: 12px;

        &-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid #e0e0e0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
          }

          div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 10px;
            background-color: #f5f5f5;

            p {
              color: #757575;
              font-size: 12px;
            }
          }

          .no-available {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 11px;
            align-items: center;
            justify-content: center;

            .img {
              width: 44px;
              height: 44px;
            }

            p {
              font-weight: 600;
              font-size: 14px;
              color: #757575;
            }
          }
        }
      }
    }

    .identity-image-sim {
      grid-column: 1 / 2;
    }

    .identity-image-ktp {
      grid-column: 2 / 3;
    }

    &__passengers-baggage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 14px;

      div.g-input {
        width: 100%;
      }
    }
  }

  .booking-zone {
    margin-top: 28px;
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    column-gap: 14px;
  }
}
