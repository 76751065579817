@use '../../../scss/abstracts/' as *;

.select-field-shuttle-zone {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    gap: 13px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    padding: 0 12px;

    &__input {
      width: 100%;
      padding: 13px 0;
      border: none;
      outline: none;
      text-transform: capitalize;
      font-size: $font-small;
      cursor: auto;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__input:focus & {
      outline-color: #0085ff;
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 84px;
    max-height: 265px;
    border-radius: 9px;
    background-color: white;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
    list-style: none;
    overflow-y: auto;
    z-index: 10;

    &__head-list {
      color: #000;
      font-size: 8px;
      font-weight: 400;
      border-top: none;
      background-color: #f0f3f5;
      padding: 7px 23px;
      pointer-events: none;
    }

    &__content-list {
      width: 100%;
      display: flex;
      align-items: center;
      // gap: 20px;
      padding: 19px 23px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      div:first-child {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 35%;

        &__name {
          color: #000;
          font-size: 12px;
          font-weight: 400;
        }
      }

      &__zone {
        font-size: 12px;
        font-weight: 600;
      }
    }

    &__empty-list {
      width: 100%;
      padding: 19px 23px;
      background-color: white;
      pointer-events: none;
    }

    &__intersection {
      width: 100%;
      padding: 19px 23px;
      background-color: white;
      pointer-events: none;
      border-top: 1px solid #e0e0e0;
    }

    li:hover {
      filter: brightness(95%);
    }
  }
}
