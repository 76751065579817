@use '../../../scss/abstracts/' as *;

.input {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__label {
    font-size: $font-small;
    font-weight: 700;
  }

  &__field {
    display: flex;
    align-items: center;
    border: none;
    outline: 1px solid #e0e0e0;
    padding: 12px 0;
    border-radius: 5px;

    &-currency {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e0e0e0;
      padding: 5px 0;
    }

    &-number {
      width: calc(100% - 70px);
      padding-left: 30px;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }
}
