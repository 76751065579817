@use '../../../../../../scss/abstracts/' as *;

.schedule-price-detail {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 24px;

  h1 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }

  .first-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    column-gap: 23px;
    row-gap: 36px;
    // border-bottom: 2px dashed #e0e0e0;
    padding-bottom: 8px;

    &__schedule-periode {
      display: flex;
      flex-direction: column;
      position: relative;

      h3 {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 18px;
      }

      &__date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 14px;

        div {
          div {
            div:last-child {
              top: 40px;
            }
          }
        }
      }
    }
  }

  .second-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      color: #000;
      font-size: 12px;
      font-weight: 700;
    }

    .radio {
      display: flex;
      gap: 24px;
      align-items: center;

      .checkbox {
        margin-left: auto;
      }
    }
  }

  .actions-button {
    margin-top: 46px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  // ======select option======
  &__select-container {
    position: relative;
    width: 100%;
    display: inline-block;
    align-self: flex-end;

    .options-container {
      display: block;
    }
  }

  &__select-label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 18px;
    line-height: normal;
  }

  &__select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    // background-color: red;

    &.disabled {
      pointer-events: none;
      background-color: #f5f5f5;
    }

    div {
      display: flex;
      align-items: center;

      & > * ~ * {
        margin-left: 13px;
      }
    }
  }

  &__options-container {
    display: none;
    margin-top: 4px;
    width: 100%;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    max-height: 150px;
    overflow-y: auto;

    .option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #e0e0e0;
      background-color: white;
      cursor: pointer;

      &:hover {
        filter: brightness(95%);
      }

      &:nth-child(1) {
        border-top: none;
      }
    }

    .option input {
      // margin-right: 10px;
      width: 18px;
      height: 18px;
    }

    .option label {
      font-size: 12px;
    }

    .option div {
      font-size: 12px;
    }

    .option.option-empty {
      display: flex;
      justify-content: center;
      font-size: 12px;
    }

    &.show {
      display: block;
    }
  }

  &__arrow {
    transform: rotate(0deg);
    transition: all ease 0.3s;
  }

  .arrow-down {
    transform: rotate(-180deg);
  }

  &__route-section {
    h3 {
      color: #000;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 18px;
    }
  }

  &__route-header {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #f7f7f7;
    border: 1px solid #d9d9d9;
    padding: 4px 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    div {
      width: 50%;
      text-align: center;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__route-value-group {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    // background-color: #f7f7f7;
    border: 1px solid #d9d9d9;

    &:nth-of-type(odd) {
      background-color: #f7f7f7;
    }
  }

  &__route-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 43px;

    &:first-of-type {
      border-right: 1px solid #d9d9d9;
    }
  }

  &__btn-remove-item {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 999px;

    &:hover {
      background-color: #d9d9d9;
    }
  }

  &__route-select-group {
    display: flex;
    width: 100%;
    align-items: center;

    border: 1px solid #d9d9d9;

    div {
      width: 100%;
    }
  }

  &__route-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    border: 1px solid #d9d9d9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__route-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #009ef7;
    font-size: 12px;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      opacity: 0.75;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background-color: #009ef7;
      border-radius: 999px;
    }
  }
}

.schedule-price-checkbox {
  position: absolute;
  right: 20px;
  top: 20px;
}
