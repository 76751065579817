@use '../../../../../scss/abstracts/' as *;
@forward './ColorVariation/';
@forward './UploadVariationImage/';

.detail-additional {
  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 36px;

    .additional-description {
      .input-text-area-title {
        margin-bottom: 0px;
      }

      .field-wrapper {
        textarea {
          height: 161px;
        }
      }
    }

    .submit-button {
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
      margin-top: 12px;
    }
  }
}
