@use '../../../scss/abstracts/' as *;

.upload-image-custom-order {
  &__container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 12px 22px;
    border-radius: 9px;
    position: relative;
    height: 164px;
    background-color: white;

    .button {
      font-size: $font-small;
      text-align: center;
      width: 100%;
      height: 100%;

      &__field {
        border-radius: 9px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 9.67px;
        border: 2px dashed #cbcbcb;
        background-color: white;
      }

      p {
        color: #757575;

        button {
          background-color: transparent;
          color: #0085ff;
          font-weight: 700;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }
    }

    &__thumbnails {
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      height: 140px;
      border: 1px solid #cbcbcb;
      border-radius: 9px;
      overflow: hidden;

      .thumbnail {
        border: none;
        width: 800px;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .active {
      filter: brightness(80%);
    }

    .drag-file-element {
      position: absolute;
      max-width: 100%;
      height: calc(165px + 12px);
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      z-index: 900;
    }

    &.disable {
      background-color: #f5f5f5;

      label {
        pointer-events: none;
      }
    }
  }
}
