/* Custom properties */

$tooltip-text-color: #000000;
$tooltip-background-color: #ffffff;
$tooltip-margin: 30px;
$tooltip-arrow-size: 6px;

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 999999;
  white-space: nowrap;

  /* CSS border triangles */
  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: $tooltip-arrow-size;
    margin-left: calc($tooltip-arrow-size * -1);
  }

  &--top {
    // top: calc($tooltip-margin * -1);
    top: -35px;

    &::before {
      top: 100%;
      border-top-color: $tooltip-background-color;
    }
  }

  &--bottom {
    bottom: calc($tooltip-margin * -1);

    &::before {
      bottom: 100%;
      border-bottom-color: $tooltip-background-color;
    }
  }

  &--right {
    // left: calc(100% + $tooltip-margin);
    left: 45px;
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: calc($tooltip-arrow-size * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: $tooltip-background-color;
    }
  }

  &--left {
    left: auto;
    right: calc(100% + $tooltip-margin);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &::before {
      left: auto;
      right: calc($tooltip-arrow-size * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: $tooltip-background-color;
    }
  }
}
