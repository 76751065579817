@use '../../../scss/abstracts/' as *;

.review-identity-modal {
  min-width: 610px;

  .identity-image-section {
    width: 610px;

    &__image-wrapper {
      background: #e0e0e0;
      width: 100%;
      height: 291px;
      object-fit: cover;
      margin-bottom: 24px;
      border-radius: 10px;
      overflow: hidden;

      img.image {
        cursor: pointer;
      }

      .template-image {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 11px;
        align-items: center;
        justify-content: center;

        p {
          font-weight: 600;
          font-size: 14px;
          color: #757575;
        }
      }
    }

    &__requirement {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      margin-bottom: 37px;

      h3 {
        font-size: 12px;
      }
    }

    &__button-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 12px;
    }
  }

  .reupload-reason-section {
    width: 610px;
    display: flex;
    flex-direction: column;
    row-gap: 22px;

    h3 {
      font-size: 18px;
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      list-style: none;

      li {
        font-size: 12px;
      }
    }

    .notes {
      .input-text-area-title {
        margin-bottom: 4px;
      }

      .field-wrapper {
        textarea {
          height: 136px;
        }
      }
    }

    &__button-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 12px;
    }
  }
}
