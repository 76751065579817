@use '../../../scss/abstracts/' as *;

.select-multiple-input {
  position: relative;

  .field-wrapper {
    .input-field {
      padding: 8px 70px 8px 12px;
      width: 100%;
      font-size: $font-small;
      border-radius: 2px;
      border: 1px solid #e0e0e0;
      height: 100%;
      min-width: 119px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &__dropdown {
    position: absolute;
    margin-top: 6px;
    padding: 0 8px;
    list-style-type: none;
    background-color: $white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    width: 100%;

    li {
      font-size: $font-small;
      padding: 12px 0;
      border-bottom: 1px solid #e0e0e0;
      display: inline-flex;

      &:hover {
        cursor: default;
      }

      &:last-of-type {
        border: none;
      }

      input {
        margin-right: 12px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
