@use '../../../../../../scss/abstracts/' as *;

.without-driver-form {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 22px;
    text-align: left;
    width: 100%;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__rental-location {
    margin-bottom: 35px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    row-gap: 38px;
    column-gap: 28px;
    padding-bottom: 40px;
    // border-bottom: 1px dashed #e0e0e0;

    div:nth-child(5) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    div:nth-child(6) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    &__jadwal-penerbangan {
      display: grid !important;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
      column-gap: 14px;
      align-items: center;
    }

    &__overtime-passenger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      div {
        width: 100%;
        position: relative;
      }
    }

    div:nth-child(9) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      div {
        width: 100%;
        position: relative;
      }
    }

    div:nth-child(10) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      div {
        width: 100%;
        position: relative;
      }
    }

    div:nth-child(11) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      div {
        width: 100%;
        position: relative;
      }
    }
  }

  &__additional-item {
    width: 100%;
    margin-bottom: 36px;

    h2 {
      color: #009ef7;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    div.wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 38px;
      column-gap: 28px;

      .additional {
        grid-column: 1 / 3;
      }

      // .input-text-area-title {
      //   margin-bottom: 4px;
      // }
    }
  }

  &__driver-assign {
    padding: 24px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;

    div {
      max-width: 100%;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  &__button-action {
    margin: 0 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;
    border-top: 1px dashed #e0e0e0;
    padding-top: 24px;

    &__action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
