.business,
.employee {
  position: relative;

  &-detail {
    &__back-btn {
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      cursor: pointer;
      margin-bottom: 21px;

      span {
        margin-left: 0.75rem;
      }
    }

    &__form {
      margin-bottom: 24px;

      .form__two-column {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 24px;
        align-items: start;
        margin-bottom: 34px;

        .text-area-input {
          label {
            margin-bottom: 0;
          }

          .field-wrapper {
            .input-text-area-field {
              height: 208px;
            }
          }
        }

        .input-group__logo-input {
          h4 {
            font-size: 12px;
            margin-bottom: 12px;
            line-height: 18px;
          }

          .logo-upload {
            display: flex;
            align-items: center;
            padding: 0 14px;
            border-radius: 5px;
            background-color: white;
            outline: 1px solid #e0e0e0;
            column-gap: 12px;
            min-height: 44px;

            .btn-upload {
              font-size: 12px;
              outline: none;
              border: none;
              padding: 5px 14px;
              border-radius: 4px;
              transition: 0.2s;
              background-color: #e0e0e0;

              &:hover {
                background-color: #f0f0f0;
                cursor: pointer;
              }
            }

            .link-upload {
              font-size: 12px;
              color: #0085ff;
              transition: 0.2s;

              &:hover {
                color: #006dd3;
                cursor: pointer;
              }
            }
          }
        }
      }

      .input-group__text-editor {
        margin-bottom: 24px;

        h4 {
          font-size: 12px;
          margin-bottom: 12px;
          line-height: 18px;
        }

        .text-area-editor__text-area {
          height: 205px;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: end;
      column-gap: 12px;
    }
  }

  .table {
    overflow-x: auto;

    .table-wrapper {
      width: 105%;
    }

    [data-type='name'] {
      max-width: 162px;
    }

    [data-type='status'] {
      width: 132px;
    }
  }
}
