@use '../../../scss/abstracts/' as *;

.vehicles-filter {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__field {
    min-width: 153px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
    background: #fff;

    &__input {
      width: calc(153px - 24px - 12px);
      height: 30px;
      font-size: 12px;
      font-weight: normal;
      color: #000;
      padding: 6px 12px;
      pointer-events: none;
      overflow: hidden;
    }

    &__chevron {
      margin-right: 12px;
      padding: 4px;
      width: 24px;
      height: 24px;
      font-size: 2rem;
      transform: rotate(0deg);
      transition: all ease 0.3s;
    }

    .down {
      transform: rotate(-180deg);
    }

    &__dropdown {
      max-height: 170.4px;
      overflow-y: auto;
      position: absolute;
      list-style: none;
      background-color: #fff;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      padding: 0 8px;
      right: 0;
      left: 0;
      top: 128%;
      z-index: 20;

      li {
        padding: 12px 0;
        font-size: 12px;
        font-weight: normal;
        color: #000;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        gap: 8px;

        label {
          cursor: pointer;
        }

        input[type='radio'] {
          cursor: pointer;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }
}
