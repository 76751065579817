@use '../../../../../../scss/abstracts/' as *;

.one-way-form {
  display: flex;
  flex-direction: column;

  &__rental-location {
    margin-bottom: 35px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    row-gap: 38px;
    column-gap: 28px;
    margin-bottom: 32px;
    border-bottom: 1px dashed #e0e0e0;
    padding-bottom: 32px;

    &__shuttle {
      position: relative;
      grid-column: 1/3;
      display: flex;
      justify-content: space-between;
      gap: 28px;

      div {
        width: 100%;
      }

      .select-field-shuttle {
        margin-bottom: 12px;
      }
    }

    &__baggage-passenger {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
    }

    .select-field-date__dropdown {
      z-index: 20;
    }
  }

  &__driver-fee {
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    row-gap: 38px;
    column-gap: 28px;
    margin-bottom: 50px;
  }

  &__button-action {
    margin: 0 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    column-gap: 28px;

    &__action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}
