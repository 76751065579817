@mixin flex($justify-content: center, $align-item: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin absolute($top: 50%, $right: unset, $bottom: unset, $left: 50%, $translateX: -50%, $translateY: -50%) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}
