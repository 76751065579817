@use '../../../scss/abstracts/' as *;

.transaction-filter-car {
  width: 100%;
  display: grid;
  grid-template-columns: 1.1fr 0.9fr 0.9fr 0.7fr;
  column-gap: 12px;
  background: $white;
  border-radius: 4px;
  padding: 16px;

  &__action {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 8px;

    .reset {
      height: 44px;
      width: 50%;
      background-color: rgba(0, 158, 247, 0.17);
      color: rgba(0, 158, 247, 1);
      font-weight: normal;
    }

    .apply {
      width: 50%;
      height: 44px;
      font-weight: normal;
    }
  }
}
