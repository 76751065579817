@use '../../../scss/abstracts/' as *;

.rental-duration-modal {
  width: 1057px;
  margin-top: 24px;

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 68px;

    .field {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }

    .action-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 10px;
    }
  }
}
