@use '../../scss/abstracts/' as *;

.login {
  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    min-width: 320px;
    background-color: $white;
    padding: 43px;
    border-radius: 22px;
  }

  &__header {
    margin-bottom: 16px;

    h1 {
      font-size: $font-larger;
      margin-bottom: 10px;
    }

    p {
      font-size: $font-medium;
      font-weight: 500;
    }
  }

  &__form {
    .g-input {
      margin-bottom: 20px;

      &:nth-child(2) {
        margin-bottom: 40px;
      }
    }
  }
}
