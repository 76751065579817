@use '../../../scss/abstracts/' as *;

.cancel-driver-task {
  width: 610px;

  &__message {
    span {
      font-weight: bold;
      font-size: 12px;
    }

    &__textarea {
      margin-top: 14px;
      margin-bottom: 24px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 12px;
  }
}
