@use '../../../../scss/abstracts/' as *;

.card-dashboard-item {
  position: relative;
  width: 100%;
  background-color: $white;
  padding: 0 21px;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  box-sizing: border-box;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;

    .title {
      font-weight: 500;
      font-size: $font-small;
      opacity: 70%;
    }

    .dropdown {
      background: white;
      position: relative;

      &__filter {
        width: 98px;
        height: 26px;
        border: solid 1px #ededed;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 6px;
        font-size: 12px;
        cursor: pointer;

        span {
          font-weight: 500;
          color: $lightBlue;
        }
      }

      &__list {
        position: absolute;
        background: white;
        width: 175px;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
        padding: 14px;
        z-index: 90;

        .list-item {
          width: 100%;
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          row-gap: 14px;
          padding-bottom: 14px;
          border-bottom: 1px solid #e0e0e0;

          li {
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-size: $font-small;
            font-weight: 500;
            cursor: pointer;
          }
        }

        .custom-filter {
          position: relative;

          .button {
            width: 100%;
            padding-top: 8px;
            font-size: $font-small;
            font-weight: 500;
            cursor: pointer;
          }

          &__dropdown {
            position: absolute;
            padding: 24px 40px;
            background-color: white;
            box-shadow: 0px 0px 5px rgba(35, 32, 32, 0.1);
            border-radius: 5px;
            z-index: 9999;
          }

          .start {
            left: -30px;
          }

          .end {
            right: -30px;
          }

          .vertical-bottom {
            top: 40px;
          }

          .vertical-top {
            bottom: 160px;
          }

          .vertical-reverse-top {
            top: 40px;
          }

          .vertical-reverse-bottom {
            bottom: 160px;
          }

          .start.horizontal {
            bottom: -30px;
            left: 150px;
          }

          .end.horizontal {
            bottom: -30px;
            right: 150px;
          }
        }
      }

      .start {
        left: 0;
      }

      .end {
        right: 0;
      }

      .top {
        bottom: 40px;
      }

      .bottom {
        top: 40px;
      }
    }
  }

  .separator {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e5e7;
  }

  .bold {
    font-size: $font-medium;
    font-weight: 700;
    opacity: 1;
  }

  &__content {
    width: 100%;
    height: 80%;
  }

  &__value {
    font-size: 20px;
    font-weight: 700;
  }
}

.card-dashboard-item-skeleton {
  width: 100%;
  background: #fafafa;
  padding: 0 21px;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  box-sizing: border-box;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;

    .title-skeleton {
      width: 180px;
      height: 18px;
      background: #eae9e9;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    .filter-skeleton {
      width: 98px;
      height: 26px;
      background: #eae9e9;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }
  }

  .separator {
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e5e7;
  }

  &__value {
    width: 120px;
    height: 40px;
    background: #eae9e9;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  &__content {
    width: 100%;
    height: 80%;
    background: #eae9e9;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
