@use '../../../../../scss/abstracts/' as *;
@forward './ContentLeft/content-left';
@forward './Contentright/content-right';

.vehicle-detail {
  // HEADER KEMBALI
  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 21px;

    p {
      margin-left: 12px;
    }
  }

  // CONTENT
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;

    .content {
      &--left {
        background-color: $white;
        border-radius: 4px;
        width: 50%;
      }
    }
  }
}
