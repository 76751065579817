@use '../../../scss/abstracts/' as *;

.select-field-date {
  position: relative;

  &__label {
    display: block;
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__input-field {
    display: flex;
    align-items: center;
    gap: 12.5px;
    padding: 0 12px;
    border-radius: 5px;
    outline: 1px solid #e0e0e0;
    background-color: white;

    &__input {
      width: 97%;
      border: none;
      outline: none;
      height: 44px;
      font-size: $font-small;
      cursor: default;
      background: transparent;

      &::placeholder {
        font-size: 12px;
      }
    }

    &__input:focus & {
      outline-color: #0085ff;
    }
  }

  .disable {
    pointer-events: none;
    background-color: #f5f5f5;
  }

  &__dropdown {
    position: absolute;
    padding: 24px 40px;
    top: 70px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(35, 32, 32, 0.1);
    border-radius: 5px;
    z-index: 10;
  }

  .right {
    left: -15px;
  }

  .left {
    right: -15px;
  }
}
