@use '../../../../scss/abstracts/' as *;

.notif {
  .add-btn {
    margin-left: auto;
    margin-bottom: 16px;
  }

  &-detail {
    &__header {
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      margin-bottom: 21px;

      p {
        margin-left: 12px;
      }
    }
    .table-title {
      h1 {
        font-size: $font-label;
        margin-left: 0;
      }
    }

    &__first {
      grid-template-columns: 313px auto;
      display: grid;
      gap: 50px;
      margin-bottom: 27px;
    }

    &__second {
      margin-bottom: 27px;

      .textarea-input {
        h3 {
          font-weight: 400;
          font-size: $font-small;
          margin-bottom: 12px;
        }
        textarea {
          height: 181px;
          width: 100%;
          resize: none;
          border-radius: 4px;
          border: none;
          outline: 1px solid #e0e0e0;
          font-size: 0.75rem;
          display: flex;
          align-items: center;
          padding: 9px 12px;
          font-weight: 500;
        }
      }
    }

    &__third {
      margin-bottom: 120px;
      width: 313px;
    }

    &__button {
      display: flex;
      justify-content: flex-end;
    }

    .g-input {
      .input-text-title,
      .input-title {
        font-weight: 400;
      }

      input::placeholder {
        font-size: $font-small;
      }

      &.input-category {
        .field-wrapper {
          position: relative;
          display: flex;
          align-items: center;

          input {
            width: 313px;
          }

          .field-icons {
            display: flex;
            margin-left: 15px;
            gap: 3px;

            .success-icon,
            .add-icon,
            .cancel-icon {
              cursor: pointer;
              border: 2px solid #0085ff;
              height: 24px;
              width: 24px;
              border-radius: 100px;
              // padding: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .success-icon {
              background-color: #0085ff;
            }

            .cancel-icon {
              border: 2px solid #b4b4b4;
            }
          }

          .select-category {
            position: absolute;
            top: 120%;
            width: 313px;
            border-radius: 6px;
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            max-height: 190px;
            overflow: auto;
          }

          .item-category {
            padding: 22px 14px;
            font-size: $font-small;
            background: #ffffff;
            cursor: pointer;

            &:hover {
              filter: brightness(95%);
            }
          }
        }
      }
    }
  }
}
