@use '../../../../../../scss/abstracts/' as *;

.refund-history {
  min-height: 80vh;

  h2.title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 18px;
    color: #000;
  }

  .histories {
    width: 100%;

    &__container {
      margin-bottom: 28px;
      background: white;
      border-radius: 14px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .history-data {
        padding: 20px 24px;

        &__header {
          width: 100%;
          margin-bottom: 22px;

          h6 {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 6px;
          }

          p {
            font-size: 10px;
            font-weight: normal;
          }
        }

        &__data {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 36px;

          .column {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .status-refund {
              width: 113px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100px;
              padding: 2px 0;
              font-size: 10px;
              font-weight: bold;
            }

            .refund-created {
              background: #eee;
              color: #000;
            }

            .refund-processed {
              background: #fdf0df;
              color: #f1a33a;
            }

            .refund-transfered {
              background: #dbffde;
              color: #299b0a;
            }

            .refund-rejected {
              background: #ffdbdc;
              color: #ea2626;
            }

            p:first-child {
              font-size: 12px;
              font-weight: normal;
            }

            p:last-child {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }

        &__transfer {
          position: relative;
          margin-top: 40px;

          label {
            font-size: 12px;
            font-weight: bold;
          }

          input {
            width: 100%;
            margin-top: 14px;
            border-radius: 2px;
            border: none;
            background: #f7f7f7;
            padding: 12px;
          }

          .button-preview {
            width: 66px;
            height: 35px;
            position: absolute;
            bottom: 7px;
            right: 10px;
          }
        }
      }

      .history-status-progress {
        width: 100%;
        border-top: 1px solid #ececec;
        background: white;

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 18px 24px;
          cursor: pointer;

          h6 {
            font-size: 14px;
            font-weight: bold;
          }
        }

        &__progress {
          width: 100%;
          padding: 0 24px;

          .container {
            position: relative;
            display: flex;
            flex-direction: column;

            .progress {
              position: relative;
              height: 55px;
              width: 12px;

              .bar {
                position: absolute;
                left: 50%;
                top: -100%;
                height: 100%;
                width: 2px;
                transform: translateX(-50%);
              }

              .active {
                background-color: #299b0a;
              }

              .reject {
                background-color: #ea2626;
              }

              .non-active {
                background-color: #cbcbcb;
              }

              .circle-gray {
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background: #cbcbcb;
              }

              .status-check {
                position: absolute;
                width: 300px;
                left: 24px;
                top: 0;
                margin-top: 4px;

                p.status {
                  font-size: 12px;
                  font-weight: normal;
                  line-height: 2px;
                  margin-bottom: 6px;
                }

                p.date {
                  font-size: 8px;
                  font-weight: normal;
                  color: #b1b1b1;
                }
              }

              .check-icon {
                position: absolute;
                top: 0;
                z-index: 50;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
