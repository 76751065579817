@use '../../../scss/abstracts/' as *;
.table {
  &-footer {
    display: flex;
    justify-content: flex-end;

    .btn {
      &-page {
        border-radius: 4px;
        margin-left: 4px;
        width: 34px;
        height: 34px;
        background-color: $white;
        border: 1px solid #e0e0e0;
        cursor: pointer;

        &.active {
          background-color: $lightBlue;
          color: $white;
        }
      }

      &-left-arrow,
      &-right-arrow {
        cursor: pointer;
        border: 1px solid #757575;
        outline: none;
        width: 37px;
        height: 37px;
        background-color: $white;
        transition: 0.2s background-color;

        img {
          margin: 0 auto;
          text-align: center;
        }

        &:hover {
          background-color: $lighterGray;
        }
      }
    }
  }
}
