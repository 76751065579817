@use '../../../scss/abstracts/' as *;

.price-simulation {
  &-modal {
    width: 497px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0 40px;
    max-height: 476px;
    overflow-y: auto;
    padding: 4px 0;
    row-gap: 20px;
    padding-right: 2px;
    overscroll-behavior-y: contain;
  }

  &__formula {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    h5 {
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__result {
    padding-top: 12px;
    h5 {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }

  &-title,
  &-value {
    font-size: $font-small;
  }

  &-title {
    margin-bottom: 8px;
  }
}
