//colors
$darkStone: #1d2939;
$darkVulcan: #0b131d;
$lightBlue: #009ef7;
$darkGray: #4c4c4c;
$lightGray: #b3b3b3;
$lighterGray: #f3f3f3;
$offWhite: #f2f4f7;

$white: #ffffff;
$black: #000000;

$backgroundColor: $offWhite;

// Font Sizes
$font-small: 0.75rem; // 12px
$font-medium: 0.875rem; // 14px
$font-label: 1rem; // 16px
$font-title: 1.125rem; // 18px
$font-large: 1.375rem; // 22px
$font-larger: 1.5rem; // 24px
$font-xlarge: 1.75rem; //28px

// Font Families
$montserrat: 'Montserrat', sans-serif;
$inter: 'Inter', sans-serif;
$poppins: 'Poppins', sans-serif;

// z-indexes
$g-modal: 9999;
$modal-overlay: 10000;
$modal: calc($modal-overlay + 1);
