@use '../../../../scss/abstracts/' as *;

.roles-assign {
  &-detail {
    &__back-btn {
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-bottom: 22px;

      span {
        margin-left: 0.75rem;
      }
    }

    &__content {
      margin-bottom: 28px;

      .select-permission {
        position: relative;
        display: block;

        .input-title {
          margin-bottom: 12px;
        }

        &-field {
          background-color: $white;
          position: relative;
          width: 100%;
          height: 44px;
          border-radius: 4px;
          border: none;
          outline: 1px solid #e0e0e0;
          font-size: $font-small;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          font-weight: 500;

          &:hover {
            cursor: pointer;
          }
        }

        &-list {
          position: absolute;
          left: 0;
          display: block;
          margin-top: 6px;
          width: 100%;
          background-color: $white;
          border-radius: 10px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          z-index: 10;
          max-height: 125px;
          overflow-y: auto;
          font-size: $font-small;

          &__title {
            padding: 12px 12px 4px 12px;
            font-size: 10px;
            font-weight: 700;
          }

          &__dropdown {
            list-style-type: none;
          }

          &__item {
            padding-left: 12px;
            padding-right: 12px;

            div {
              border-bottom: 1px solid #e0e0e0;
              padding-top: 12px;
              padding-bottom: 12px;
            }

            &:hover {
              background-color: $offWhite;
              cursor: pointer;
            }
          }
        }
      }
    }

    &__body {
      background-color: $white;
      border-radius: 6px;
      padding: 22px 24px;
      margin-bottom: 26px;

      &-add {
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 20px;

        p {
          color: $lightBlue;
          font-size: $font-small;
          margin-left: 4px;
        }
      }
    }

    &__button {
      text-align: right;

      button {
        display: inline-block;
      }
    }
  }
}
