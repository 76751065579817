.input-field-map {
  .g-input {
    margin-bottom: 12px;
    position: relative;

    .list-place {
      position: absolute;
      left: 0;
      right: 0;
      top: 84px;
      max-height: 265px;
      border-radius: 9px;
      background-color: white;
      box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
      list-style: none;
      overflow-y: auto;
      z-index: 10;

      &-item {
        padding: 7px 23px;

        &:hover {
          background-color: #f7f7f7;
          cursor: pointer;
        }
      }
    }

    .input-icon-left {
      z-index: 5;
    }
  }

  .leaflet-container {
    height: 220px;
    z-index: 0;

    .leaflet-bar {
      border: none !important;
      width: fit-content;
    }
  }
}
