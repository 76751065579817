@use '../../../../../../../scss/abstracts/' as *;

.potongan-langsung-form {
  display: grid;
  grid-template-columns: minmax(500px, 1fr) minmax(500px, 1fr);
  row-gap: 38px;
  column-gap: 28px;

  &__periode {
    display: flex;
    flex-direction: column;
    gap: 16px;
    // position: relative;

    h3 {
      font-size: 12px;
    }

    &__date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 14px;

      .select-field-date {
        &__dropdown {
          top: 40px;
        }
      }
    }
  }
}
