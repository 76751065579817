@use '../../../../../scss/abstracts/' as *;

.metode-pembayaran {
  margin-top: 32px;

  &__form {
    &__title {
      color: $lightBlue;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 22px;
    }

    &__form {
      display: grid;
      grid-template-columns: repeat(2, minmax(400px, 1fr));
      gap: 28px;

      div:nth-child(5) {
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          h3 {
            color: black;
            font-size: $font-small;
            font-weight: 700;
          }
        }
      }
    }
  }

  &__button-action {
    margin: 61px 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
}
