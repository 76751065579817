@use '../../scss/abstracts/' as *;

#modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $g-modal;

  .g-modal {
    position: relative;
    width: 100vw;
    height: 100vh;

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: $modal-overlay;
    }
  }

  .modal {
    position: absolute;
    z-index: $modal;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    padding: 18px 24px;
    border-radius: 6px;

    &-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 14px;

      // & > * ~ * {
      //   position: absolute;
      //   right: 120px;
      // }
    }

    &-headerNotification {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      padding: 4px;
      font-size: 14px;
      cursor: pointer;
      border: none;

      & > svg {
        margin-right: 6px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      h1 {
        font-size: $font-medium;
      }
    }

    figure {
      margin-right: 10px;
      // max-height: 25px;
    }
  }
}
