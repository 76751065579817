@use '../../../../../../scss/abstracts/' as *;

.withdraw {
  display: flex;
  flex-direction: column;
  row-gap: 21px;

  &__header {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    p {
      margin-left: 12px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
  }

  &__driver-detail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 29px;
    row-gap: 36px;
    margin-top: 24px;

    .image-wrapper {
      position: relative;

      .button-preview {
        width: 28px;
        position: absolute;
        top: 37px;
        right: 14px;
      }
    }
  }

  &__saldo-wrapper {
    width: 100%;
    background: white;
    border-radius: 6px;
    padding: 24px;
  }

  &__saldo-detail {
    margin-top: 14px;
    width: 100%;
    border-radius: 14px;
    padding: 18px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 28px;

    p:first-child {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    p:last-child {
      font-size: 20px;
      font-weight: bold;
      color: #009ef7;
    }
  }

  &__fee-detail {
    position: relative;
    margin-top: 14px;
    width: 100%;
    border-radius: 14px;
    padding: 18px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 28px;

    p:first-child {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    p:nth-child(2) {
      font-size: 20px;
      font-weight: bold;
      color: #009ef7;
    }

    .action-button {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      column-gap: 10px;

      .button-withdraw {
        padding: 12px 21px;
        border-radius: 10px;
      }

      .cancel {
        background: #ea2626;
      }

      .confirmation {
        background: #299b0a;
      }
    }

    .transfer-proof-download {
      position: absolute;
      top: -30px;
      right: 18px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #009ef7;
      }
    }

    .transfer-proof-download:hover {
      p {
        text-decoration: underline;
      }
    }

    // .button-upload,
    // .button-download {
    //   position: absolute;
    //   top: 50%;
    //   right: 18px;
    //   transform: translateY(-50%);
    //   padding: 12px 21px;
    //   border-radius: 10px;
    //   display: flex;
    //   align-items: center;
    //   column-gap: 10px;
    //   background: white;
    //   color: #009ef7;
    //   border: 1px solid #009ef7;
    // }
  }

  &__transaction {
    width: 100%;
    list-style: none;
    margin-bottom: 24px;

    li {
      border-bottom: 1px solid #e0e0e0;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        p:first-child {
          font-size: 12px;
          font-weight: 600;
        }

        p:last-child {
          font-size: 10px;
          font-weight: 400;
          color: #a8a8a8;
        }
      }

      p.amount {
        font-size: 12px;
        font-weight: bold;
      }

      .plus {
        color: #299b0a;
      }

      .minus {
        color: #ea2626;
      }
    }

    li:last-child {
      border-bottom: none;
    }

    .empty-data {
      margin-top: 18px;
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      color: #a8a8a8;
    }
  }
}
