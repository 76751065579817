@use '../../../../scss/abstracts/' as *;
@forward './DetailPenyewa/detail-penyewa.scss';
@forward './DetailSewa/detail-sewa.scss';
@forward './KonfirmasiPesanan/DetailPembayaran/';
@forward './MetodePembayaran/metode-pembayaran.scss';

.custom-order {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // min-height: 150vh;
  margin-bottom: 120px;

  &__form-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
}
