@use '../../../../scss/abstracts/' as *;
.detail-deposit-order {
  &__back-btn {
    display: flex;
    width: fit-content;
    margin-bottom: 21px;

    &:hover {
      cursor: pointer;
    }

    p {
      margin-left: 12px;
      font-size: $font-medium;
    }
  }

  &__detail,
  &__violations-detail,
  &__additionals-detail {
    &-title {
      margin-bottom: 18px;

      h1 {
        color: $lightBlue;
        font-size: $font-label;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: flex-end;

      .input-text-field {
        background-color: #f7f7f7;
        outline: none;
      }

      .input-order-key {
        .field-wrapper {
          background-color: #f7f7f7;
          outline: none;

          .input-text-field {
            width: fit-content;
            color: #0084ff;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
              font-weight: 600;
              text-decoration: underline;
            }
          }
        }
      }

      &__image-wrapper {
        position: absolute;
        right: 0;
        top: 0;
      }

      &__preview-image {
        position: relative;

        .preview-image-btn {
          position: absolute;
          bottom: 5px;
          right: 8px;
        }

        .preview-image-input {
          &.disabled {
            .input-text-field {
              padding-right: 100px;
              background-color: #f7f7f7;
              outline: none;
            }
          }

          .input-text-field {
            padding-right: 100px;
            background-color: #ffffff;
            outline: 1px solid #e0e0e0;
          }
        }
      }

      &__input-violation,
      &__input-additional {
        margin-top: 28px;
      }

      &__input-additional {
        padding-bottom: 36px;
        margin-bottom: 20px;
        border-bottom: 1px solid #dbdbdb;
      }
    }
  }

  &__total-payment {
    &__total {
      width: 360px;
      margin-left: auto;
      margin-right: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #e7f3ff;
      border-radius: 5px;
      padding: 14px;

      p {
        color: #1c3d5d;
        font-weight: 700;
      }

      &:last-of-type {
        margin-top: 15px;
      }
    }
  }

  &__transaction-buttons {
    margin-top: 48px;
    text-align: right;
    .button {
      display: inline-block;
      margin-left: 7px;
      font-weight: bold;

      &--disabled {
        background-color: #dedede;
        color: $darkGray;
      }
    }
  }
}
