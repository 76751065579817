@use '../../../../scss/abstracts/' as *;

.contact {
  width: 100%;

  table {
    .table-head :nth-child(2) {
      width: 186px;
    }
  }
}
